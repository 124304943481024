Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.logoText = "THE METER READING SERVICES";


exports.successMessageText = "Reading added successfully!";
exports.successMessageSubText = "Admin has received the readings and will verify. Thank you!";
exports.addNewReadingButtonText = "Add another reading";
exports.goToHomeButtonText = "Back to home";

// Customizable Area End