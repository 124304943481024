export const image = require("../assets/view_services.png");
export const banner = require("../assets/image_banner-01.jpg.png");
export const machine = require("../assets/image_main.png");
export const meterService = require("../assets/image_cta-01a.jpg.png");
export const costomizedService = require("../assets/image_cta-02a.jpg.png");
export const inspectionService = require("../assets/image3.png");
export const clintService = require("../assets/image4.png");
export const navLogo = require("../assets/image_logo.png");
export const footer = require("../assets/footer.jpg");
export const servicesBanner = require("../assets/bg-cta.jpg");
export const servicesSectionBanner = require("../assets/middlefooter.jpeg");
export const otherPagesBanner = require("../assets/other_pages_banner.jpg.png");
export const otherPagesMobileUiBanner = require("../assets/other_pages_mobile_ui_banner.png");