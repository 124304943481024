import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip, IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import * as Yup from "yup";

import { Formik } from "formik";
import { banner, navLogo, errorIcon } from "./assets";
// Customizable Area End

import LogInPageController, { Props, configJSON } from "./LogInPageController";
// Customizable Area Start
import Snackbar from '@mui/material/Snackbar';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

// Customizable Area End
export default class LogInPage extends LogInPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.signUpContainer}>
        <Box sx={webStyle.leftBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.signUpFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>
                {configJSON.logInText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.welcomeBackText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
                this.doLogIn();
              }}
              data-test-id="LogInForm"
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"email"}>
                        {configJSON.emailText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...(this.state.validate && errors.email ? webStyle.inputElementError : {})}}
                        data-test-id="email"
                        type="email"
                        placeholder={configJSON.emailPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.email && (
                        <Tooltip
                          open={Boolean(errors.email)}
                          title={errors.email}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"password"}>
                        {configJSON.passwordText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...webStyle.inputElementPassword, ...(this.state.validate && errors.password ? webStyle.inputElementError : {})}}
                        data-test-id="password"
                        type={!this.state.showPassowrd ? "password" : "text"}
                        placeholder={configJSON.passwordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.password ? (
                        <Tooltip
                          open={Boolean(errors.password)}
                          title={errors.password}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      ) : (
                        <IconButton
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowPassword(!this.state.showPassowrd)
                          }
                        >
                          {this.state.showPassowrd ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      )}

                      <button
                        type="button"
                        style={webStyle.forgotPaswordButton}
                        onClick={() => {
                          this.goToForgotPasswordPage();
                        }}
                      >
                        {configJSON.forgotPasswordText}
                      </button>
                    </Box>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="button"
                      onClick={() => {
                        this.setValidate(true);
                        this.setFormDate(values);
                        if (Object.keys(errors).length == 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      {configJSON.logInText}
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box sx={webStyle.signUpRedirection}>
              <Typography style={webStyle.signUpRedirectionLabel}>
                {configJSON.donthaveAnAccountText}
              </Typography>
              <button
                type="button"
                style={webStyle.signUpRedirectionButton}
                onClick={() => {
                  this.goToSignUpPage();
                }}
              >
                {configJSON.signUpText}
              </button>
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.rightBlock}>
          <Box sx={webStyle.rightBlockBackground} />
          {/* Bkock right */}
        </Box>
        <Snackbar
          open={!!this.state.warningMessage}
          message={(
            <Box sx={webStyle.snackbarContent}>
              <CancelRoundedIcon sx={webStyle.snackbarIcon} />
              <Typography sx={webStyle.snackbarText}>{this.state.warningMessage}</Typography>
            </Box>
          )}
          autoHideDuration={3500}
          onClose={() => this.setState({ warningMessage: '' })}
          sx={webStyle.snackbar}
        />
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const webStyle = {
  signUpContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  rightBlock: {
    flex: 1,
    display: "flex",
  },
  rightBlockBackground: {
    flex: 1,
    display: "flex",
    margin: "20px",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    borderRadius: "24px",
    height: "880px",
  },
  leftBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "40px auto",
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
    fontFamily: "'Inter', sans-serif",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "'Inter', sans-serif",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
  },
  inputElementPassword: {
    padding: '10px 52px 10px 10px',
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  passwordHelp: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  checkboxLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  checkboxElemnt: {
    height: "26px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #64748B",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
  },
  signUpRedirection: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "20px auto",
    width: "max-content",
  },
  signUpRedirectionLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: "8px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  signUpRedirectionButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
  },
  forgotPaswordButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    textAlign: "right" as const,
    marginTop: "20px",
    marginBottom: "40px",
    width: 'fit-content',
    marginLeft: 'auto',
  },
  snackbar: {
    left: '25% !important',
    transform: 'translateX(-50%)',
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: '#FFFFFF',
      fontFamily: "'Inter', sans-serif !important",
      padding: '8px 16px 8px 8px',
      boxShadow: '0px 6px 15px -3px #00000026',
      borderRadius: '8px',
      minWidth: 'auto',
      "& .MuiSnackbarContent-message": {
        padding: 0,
      }
    }
  },
  snackbarContent: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  snackbarIcon: {
    width: '26px',
    height: '26px',
    '> path': {
      fill: '#F87171',
    }
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  }
};
// Customizable Area End
