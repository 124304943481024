import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reports {
  id: string;
  tenant: string;
  meter: string;
  consumption: string;
  date: string;
}

interface S {
  reportList: Reports[];
  dashboardFilter: number;
  customerId: number;
  propertyName: string;
  tenant: string[];
  meter: string[];
  meterType: string;
  tennatList: string[];
  meterList: string[];
  customerList: string[];
  chartType: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class AnaliticsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      dashboardFilter: 0,
      customerId: 0,
      propertyName: "",
      tenant: [],
      meter: [],
      meterType: "",
      chartType: "line",
      reportList: [
        {
          id: "1",
          tenant: "Subway",
          meter: "JH8263",
          consumption: "276,139",
          date: "1/10/2024",
        },
        {
          id: "2",
          tenant: "Mango",
          meter: "HB1635",
          consumption: "276,139",
          date: "1/10/2024",
        },
        {
          id: "3",
          tenant: "Acme",
          meter: "CV1675",
          consumption: "276,139",
          date: "1/10/2024",
        },
        {
          id: "4",
          tenant: "Subway",
          meter: "JH8263",
          consumption: "276,139",
          date: "2/10/2024",
        },
        {
          id: "5",
          tenant: "Mango",
          meter: "HB1635",
          consumption: "276,139",
          date: "2/10/2024",
        },
        {
          id: "6",
          tenant: "Acme",
          meter: "CV1675",
          consumption: "276,139",
          date: "2/10/2024",
        },
      ],
      tennatList: ["Subway", "Mango", "Acme"],
      meterList: ["JH8263", "HB1635", "CV1675"],
      customerList: ["Acme Group.", "Acme Group. 2", "Acme Group. 3"],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchReports = (search: string) => {
    console.log({ search });
  };

  downloadReport = (id: string) => {
    console.log({ id });
  };

  downloadInvoice = (id: string) => {
    console.log({ id });
  };

  changeDashboardFilter = (value: number) => {
    this.setState({ dashboardFilter: value });
  };

  changePropertyName = (value: string) => {
    this.setState({ propertyName: value });
  };

  changeTenant = (value: string[]) => {
    this.setState({ tenant: value });
  };

  changeMeterType = (value: string) => {
    this.setState({ meterType: value });
  };

  changeMeter = (value: string[]) => {
    this.setState({ meter: value });
  };

  changeChartType = (chartType: string) => {
    this.setState({ chartType });
  };

  // Customizable Area Start
  changeCustomerId = (customerId: number) => {
    this.setState({ customerId })
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "admin") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  };
  // Customizable Area End
}
