export const searchIcon = require("../assets/search.svg");
export const filterIcon = require("../assets/filter.svg");
export const addIcon = require("../assets/add.svg");
export const editIcon = require("../assets/edit.svg");
export const deleteIcon = require("../assets/delete.svg");
export const leftArrowIcon = require("../assets/leftArrow.svg");
// export const navLogo = require("../assets/image_logo.png");

// export const passwordHiddenIcon = require("../assets/passwordHiddenIcon.svg");
// export const errorIcon = require("../assets/errorIcon.svg");
