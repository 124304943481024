import React from "react";
// Customizable Area Start
import { Typography,
  Box,  
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField, 
  IconButton, 
  InputAdornment
  } from "@mui/material";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import CloseRoundedIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {
  searchIcon,
  noPropertieisIcon,
 deleteIcon,
 editIcon,
 mailIcon,
 errorIcon,
} from "./assets";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
// Customizable Area End

import fieldRepresentativesPageController, {
  PaymentStatus,
  Props,
  configJSON,
} from "./fieldRepresentativesPageController";
import { borderRadius } from "react-select/src/theme";
// Customizable Area Start

// Customizable Area End
export default class fieldRepresentativesPage extends fieldRepresentativesPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.reportsContainer}>
        {this.state.reportList.length === 0 ? (
          <Box sx={webStyle.noReportBlock}>
            <Box sx={webStyle.noReportBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography style={webStyle.noReportTitle}>
                {configJSON.noReportTitleText}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.reportsTableHeader}>
              <Typography sx={webStyle.reportTitle}>
                {configJSON.headingText}
              </Typography>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchReports(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
                <Box>
                <Button
                style={{
                  width: "120px",
                  height: "44px",
                  padding: "10px 16px",
                  gap: "8px",
                  borderRadius: "8px",
                  backgroundColor: "#1A7BA4",
                  fontFamily: "'Inter', sans-serif",
                  color: "white",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={()=>this.setState({addFRdialog:true})}
              >
                <AddIcon style={{ fontWeight:"700" }} />
                <Typography style={{ fontWeight:"700", whiteSpace:"nowrap", textTransform: 'none', fontFamily: "'Inter', sans-serif" }}>Add FR</Typography>
              </Button>
                </Box>
              </Box>
              <Typography sx={webStyle.reportCount}>
                {this.state.reportList.length + " results"}
              </Typography>
            </Box>
            <Box sx={this.state.totalPages > 1 ? { height: '714px' } : undefined}>
              <Box sx={webStyle.reportsTable}>
                <Box sx={webStyle.reportsTableHead}>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.firstNameText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.lastNameText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.lastLogInText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.statusText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.typeText}
                  </Typography>
                  <Typography style={webStyle.headerTitleAction}>
                    {configJSON.actionsText}
                  </Typography>
                </Box>
                {this.state.currentReports.map((report) => (
                  <Box sx={webStyle.reportsTableBody} key={report.id}>
                    <Box sx={webStyle.reportItem}>{report.reportId}</Box>
                    <Box sx={webStyle.reportItem}>{report.propertysName}</Box>
                    <Box sx={webStyle.reportItem}>{report.generatedAt}</Box>
                    <Box sx={webStyle.reportItem}>
                      {PaymentStatus.Paid === report.paymentStatus && (
                        <Typography sx={webStyle.paidStyle}>
                          ACTIVE
                        </Typography>
                      )}
                      {PaymentStatus.Pending === report.paymentStatus && (
                        <Typography sx={webStyle.pendingStyle}>
                          INVITE SENT
                        </Typography>
                      )}
                      {PaymentStatus.NoInvoice === report.paymentStatus && (
                        <Typography sx={webStyle.noInvoiceStyle}>
                          {report.paymentStatus}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={webStyle.reportItem}>
                        <IOSSwitch sx={{ m: 1 }} defaultChecked />
                    </Box>
                    <Box sx={webStyle.reportItemAction}>
                        <button
                          style={{
                            ...webStyle.downloadButton,
                            width: '150px',
                          }}
                          onClick={() => {
                            this.resendInvite(report.id);
                          }}
                        >
                          <img src={mailIcon} alt="" style={webStyle.btnIcon} />
                          <Typography style={webStyle.typographyText}>Resend invite</Typography>
                        </button>

                        <button
                          style={{
                            ...webStyle.downloadButton,
                            backgroundColor: "#fff", 
                            color: "#1A7BA4",
                            border: "1px solid #1A7BA4",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            height:"36px",
                            width:"76px",
                          }}
                          onClick={() => {
                            this.editReport(report.id);
                          }}
                        >
                          <img src={editIcon} alt="" style={webStyle.btnIcon} />
                          <Typography style={webStyle.typographyText}>Edit</Typography>
                        </button>

                        <button
                          style={{
                            ...webStyle.downloadButton,
                            backgroundColor: "#fff", 
                            color: "#dc3545", 
                            border: "1px solid #dc2626", 
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            height:"36px",
                            width:"90px"
                          }}
                          onClick={() => {
                            this.setState({deleteDialouge:true})
                          }}
                        >
                          <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                          <Typography style={webStyle.typographyText}>Delete</Typography>
                        </button>
                      </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {this.state.totalPages > 1 &&
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={() => this.handlePrevPage()}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={this.state.currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon sx={this.state.currentPage === 1 ? webStyle.chevronIconDisabled : webStyle.chevronIconActive} />
                  </button>

                  {this.state.totalPages <= 3 ? (
                    [...Array(this.state.totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width: this.state.currentPage === page + 1 ? '24px' : '16px',
                          height: '24px',
                          backgroundColor:
                            this.state.currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: '12px',
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.currentPage !== page + 1
                                ? 400
                                : 700,
                        }}
                        onClick={() => this.handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {this.state.currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: '16px',
                            height: '24px',
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() => this.handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {this.state.currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: '#64748B' }}>...</span>
                      )}

                      {this.state.currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: '24px',
                            height: '24px',
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage - 1)
                          }
                        >
                          {this.state.currentPage - 1}
                        </button>
                      )}

                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: '24px',
                          height: '24px',
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: '12px',
                          fontFamily: "'Inter', sans-serif"
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage)
                        }
                      >
                        {this.state.currentPage}
                      </button>

                      {this.state.currentPage < this.state.totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: '16px',
                            height: '24px',
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage + 1)
                          }
                        >
                          {this.state.currentPage + 1}
                        </button>
                      )}

                      {this.state.currentPage < this.state.totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: '#64748B' }}>...</span>
                      )}

                      {this.state.currentPage !== this.state.totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width: this.state.currentPage === this.state.totalPages ? '24px' : '16px',
                            height: '24px',
                            backgroundColor:
                              this.state.currentPage === this.state.totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              this.state.currentPage === this.state.totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              this.state.currentPage !== this.state.totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              this.state.currentPage !== this.state.totalPages
                                  ? 400
                                  : 700,
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.totalPages)
                          }
                        >
                          {this.state.totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => this.handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={this.state.currentPage === this.state.totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon sx={this.state.currentPage === this.state.totalPages ? webStyle.chevronIconDisabled : webStyle.chevronIconActive} />
                  </button>
                </Box>
  
                <Box sx={{ marginRight: "10px", width: "120px"}}>
                  <Typography
                    fontFamily={"'Inter', sans-serif"}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                    {this.state.reportList.length} results
                  </Typography>
                </Box>
              </Box>
            }
          </>
        )}
        {/* //dialogue for delete button */}
            <Dialog
              open={this.state.deleteDialouge}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  width: "633px", 
                  padding: "40px 32px 32px 32px",
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "600", padding: '0 0 24px',fontFamily:"'Inter', sans-serif", fontSize:'24px', letterSpacing: '-1px' }}
        >
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent style={{ marginBottom: '32px', padding: 0 }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#64748B", fontFamily:"'Inter', sans-serif",fontSize:"18px"}}
          >
            This action can’t be undone and all the data stored in this account
            won’t be accessible anymore.
          </DialogContentText>
          <Divider style={{ marginTop: '32px' }} />
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <Button
            onClick={this.handleClose}
            variant="outlined"
            sx={webStyle.cancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={this.deleteReport}
            sx={webStyle.confirmDelete}
            startIcon={<DeleteIcon />} 
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

        {/* dialog for add FR */}

        <Dialog 
        open={this.state.addFRdialog} 
        onClose={this.handleCloseAddFR} 
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
          },
        }}
      >
        <DialogTitle style={{ padding: '0 0 16px', position: 'relative' }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            Add Field Representative
          </Typography>
          <IconButton
            onClick={this.handleCloseAddFR}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider style={{margin:"16px 0"}} />
        <DialogContent style={{ padding: '0 0 16px' }}>
        <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
            <div style={{ flex: 1 }}>
              <Typography variant="body2" sx={webStyle.inputLable}>
                First name
              </Typography>
              <StyledTextField 
                fullWidth 
                variant="outlined" 
                autoComplete="new-firstname" 
                placeholder="John"
                value={this.state.firstName}
                onChange={this.setFirstName}
                error={this.state.firstNameError !== ""}
                InputProps={{
                  endAdornment: this.state.firstNameError ? (
                    <InputAdornment position="end">
                      <img
                        src={errorIcon}
                        alt=""
                        style={webStyle.errorIcon}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
              {this.state.firstNameError && (
          <Typography variant="caption" sx={webStyle.filedError}>
            {this.state.firstNameError}
          </Typography>
        )}
            </div>
            <div style={{ flex: 1 }}>
              <Typography variant="body2" sx={webStyle.inputLable}>
                Last name
              </Typography>
              <StyledTextField 
                fullWidth 
                variant="outlined" 
                autoComplete="new-lastname" 
                placeholder="Doe"
                value={this.state.lastName}
                onChange={this.setLastName}
              error={this.state.lastNameError !== ""}
              InputProps={{
                endAdornment: this.state.lastNameError ? (
                  <InputAdornment position="end">
                    <img
                      src={errorIcon}
                      alt=""
                      style={webStyle.errorIcon}
                    />
                  </InputAdornment>
                ) : null,
              }}
              />
               {this.state.lastNameError && (
          <Typography variant="caption" sx={webStyle.filedError}>
            {this.state.lastNameError}
          </Typography>
        )}
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Typography variant="body2" sx={webStyle.inputLable}>
              Email
            </Typography>
            <StyledTextField
              fullWidth
              type="email"
              placeholder="email@example.com"
              value={this.state.email}
              onChange={this.setEmail}
              variant="outlined"
              error={this.state.emailError !== ""}
              InputProps={{
                endAdornment: this.state.emailError ? (
                  <InputAdornment position="end">
                    <img
                      src={errorIcon}
                      alt=""
                      style={webStyle.errorIcon}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
             {this.state.emailError && (
        <Typography variant="caption" sx={webStyle.filedError}>
          {this.state.emailError}
        </Typography>
      )}
          </div>
          <div style={{ position: 'relative', marginBottom: '16px' }}>
          <Typography variant="body2" sx={webStyle.inputLable}>
              Password
            </Typography>
            <StyledTextField
              fullWidth
              type={this.state.showPassword ? 'text' : 'password'}
              variant="outlined"
              autoComplete="off"
              placeholder="••••••••••"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton onClick={this.toggleShowPassword}>
                      {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    {this.state.passwordError && (
                      <InputAdornment position="end">
                        <img
                          src={errorIcon}
                          alt=""
                          style={webStyle.errorIcon}
                        />
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              error={this.state.passwordError !== ""}
            />
              {this.state.passwordError && (
        <Typography variant="caption" sx={webStyle.filedError}>
          {this.state.passwordError}
          </Typography>
        )}
          <div style={{ marginTop: '8px', fontSize: '13px' }}>
            <Typography style={webStyle.passwordalidation}>
              <span style={{ color: this.state.passwordValidations.hasCapital ? '#34D399' : '#DC2626', fontSize: '12px' }}>
                {this.state.passwordValidations.hasCapital ? <CheckCircleIcon style={{ fontSize: '12px' }} /> : <RemoveCircleIcon style={{ fontSize: '12px' }} />}
              </span>
              {' '}At least one capital letter
            </Typography>
            <Typography style={webStyle.passwordalidation}>
              <span style={{ color: this.state.passwordValidations.hasLowercase ? '#34D399' : '#DC2626', fontSize: '12px' }}>
                {this.state.passwordValidations.hasLowercase ? <CheckCircleIcon style={{ fontSize: '12px' }} /> : <RemoveCircleIcon style={{ fontSize: '12px' }} />}
              </span>
              {' '}At least one lowercase letter
            </Typography>
            <Typography style={webStyle.passwordalidation}>
              <span style={{ color: this.state.passwordValidations.hasNumber ? '#34D399' : '#DC2626', fontSize: '12px' }}>
                {this.state.passwordValidations.hasNumber ? <CheckCircleIcon style={{ fontSize: '12px' }} /> : <RemoveCircleIcon style={{ fontSize: '12px' }} />}
              </span>
              {' '}At least one number
            </Typography>
            <Typography style={webStyle.passwordalidation}>
              <span style={{ color: this.state.passwordValidations.minLength ? '#34D399' : '#DC2626', fontSize: '12px' }}>
                {this.state.passwordValidations.minLength ? <CheckCircleIcon style={{ fontSize: '12px' }} /> : <RemoveCircleIcon style={{ fontSize: '12px' }} />}
              </span>
              {' '}Minimum character length is 8 characters
            </Typography>
          </div>
          </div>
          <div style={{ position: 'relative', marginBottom: '24px' }}>
          <Typography variant="body2" sx={webStyle.inputLable}>
           Confirm password
            </Typography>
            <StyledTextField
              fullWidth
              type={this.state.showConfirmPassword ? 'text' : 'password'}
              variant="outlined"
              value={this.state.confirmPassword}
                onChange={this.setConfirmPassword}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton onClick={this.toggleShowConfirmPassword}>
                        {this.state.showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                      {this.state.confirmPasswordError && (
                        <InputAdornment position="end">
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              error={this.state.confirmPasswordError !== ""}
              placeholder="••••••••••"
            />
              {this.state.confirmPasswordError && (
                <Typography variant="caption" sx={webStyle.filedError}>
                  {this.state.confirmPasswordError}
                </Typography>
              )}
          </div>
          <Divider style={{margin:"16px 0"}} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
            <Button 
              onClick={this.handleCloseAddFR} 
              variant="outlined" 
              sx={{
                borderColor: '#1A7BA4', 
                borderRadius: '8px',
                color: '#1A7BA4', 
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 700,
                fontFamily: "'Inter', sans-serif",
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={this.handleSave} 
              variant="contained" 
              sx={{
                backgroundColor: '#1A7BA4', 
                borderRadius: '8px',
                color: 'white', 
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 700,
                fontFamily: "'Inter', sans-serif",
                boxShadow: 'none',
              }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "41.14px",
  height: "24px",
  // borderRadius:"34.29px",
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1A7BA4',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: "20.57px",
    height: "20.57px",
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: "#CBD5E1", 
    },
   
    '&.Mui-focused fieldset': {
      borderColor: "black",
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '16px', 
    color: '#0f172A', 
    fontFamily: "'Inter', sans-serif",
    height:"56px",
    padding:"0px 12px",
    borderRadius:"8px",
    '&::placeholder': {
      fontFamily: "'Inter', sans-serif",
      color: '#94A3B8',
      fontSize: '16px',
      opacity: 1, // Firefox requires this
    },
  },
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px white inset', 
  },
}));

const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px",
    minHeight: "100%",
    backgroundColor:"#F8FAFC"

  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "40px 0px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "auto",
  },
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderRadius: '12px 12px 0 0',
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "white",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    width:"340px"
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    width:"340px"
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    left: 310,
  },
  btnIcon: {
    width: "20px",
    height: "20px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(20% - 68px)",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    margin: "12px 0px",
  },
  downloadButton: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: 'center',
    gap: "5px", 
    backgroundColor: "#1A7BA4", 
    color: "#fff", 
    border: "none", 
    borderRadius: "5px", 
    cursor: "pointer",
    whiteSpace: "nowrap",
    width:"143px",
    height:'36px',
    fontWeigth:"600"
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(20% - 68px)",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
  },
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    display: "flex",
    flexDirection: "row",
    margin: "13px 0px",
    alignItems: "center",
    justifyContent: "space-between",
    gap:"10px"
  },
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    whiteSpace: "nowrap"
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  reportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    marginBottom:"24px"
  },
  reportCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    margin: "24px 0px 16px"
  },
  inputLable: {
    fontWeight: "700",
    marginBottom:"4px",
    fontFamily: "'Inter', sans-serif",
    fontSize:"14px",
    color: '#334155',
  },
  passwordalidation: {
    color: '#475569', 
    fontSize: '13px',
    fontFamily: "'Inter', sans-serif",
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
 filedError:{
  color: '#DC2626',
  marginTop: '4px',
  fontFamily: "'Inter', sans-serif",
  fontSize: '13px',
 },
 confirmDelete:{
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor:"white",
    color:"#DC2626",
    border:"1px solid #DC2626",
    fontFamily:"'Inter', sans-serif",
    fontWeight:"600",
    height: '44px',
    marginLeft: '16px !important'
 },
cancelButton:{
    color: "#1A7BA4",
    border: "1px solid #1A7BA4",
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    fontWeight:"700",
    fontFamily:"'Inter', sans-serif",
    fontSize: '16px',
    height: '44px',
},
  dialogTitleText: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily:"'Inter', sans-serif",
  },
  errorIcon: {
    width: '24px',
    height: '24px',
  },
  chevronIconActive: {
    '> path': {
      fill: '#475569',
    }
  },
  chevronIconDisabled: {
    '> path': {
      fill: '#64748B',
    }
  }
};
// Customizable Area End
