import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { navLogo } from "../../blocks/landingpage/src/assets";
export const configJSON = require("../../blocks/landingpage/src/config");
import { otherPagesBanner } from "../../blocks/landingpage/src/assets";

interface NavigationState {
  isSideBarOpen: boolean;
  isDesktopScreen: boolean;
}
interface NavigationProps{}

export default class AppHeader extends Component<NavigationProps, NavigationState> {
  constructor(props: NavigationProps) {
    super(props);
    this.state = {
      isSideBarOpen: false,
      isDesktopScreen: window.innerWidth > 1036
    };
  this.handleNavToggle = this.handleNavToggle.bind(this);
  this.handleResize = this.handleResize.bind(this);
}

componentDidMount() {
  window.addEventListener('resize', this.handleResize);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.handleResize);
}

handleResize() {
  this.setState({ isDesktopScreen: window.innerWidth > 1036 });
}

  handleNavToggle = () => {
    this.setState(prevState => ({
      isSideBarOpen: !prevState.isSideBarOpen
    }));
  };
  
  isShowBannerImage() {
    const validPaths = ['/Aboutus', '/Services', '/Testimonials', '/Contactus'];
    const currentPath = location.pathname;
    return validPaths.some(path => currentPath.includes(path));
  }

  getLinkStyle(path: string) {
    return location.pathname === path ? styles.activeLink : {};
  }

  getNavLinks() {
    return (
      <>
      <Link to="/" style={{ width: "100%" }}>
              <Button sx={{ ...styles.navButton, ...this.getLinkStyle('/') }}>
                {configJSON.home}
              </Button>
            </Link>
            <Link to="/Aboutus" style={{ width: "100%" }}>
              <Button sx={{ ...styles.navButton, ...this.getLinkStyle('/Aboutus') }}>
                {configJSON.about}
              </Button>
            </Link>
            <Link to="/Services" style={{ width: "100%" }}>
              <Button sx={{ ...styles.navButton, ...this.getLinkStyle('/Services') }}>
                {configJSON.service}
              </Button>
            </Link>
            <Link to="/Testimonials" style={{ width: "100%" }}>
              <Button sx={{ ...styles.navButton, ...this.getLinkStyle('/Testimonials') }}>
                {configJSON.testimonial}
              </Button>
            </Link>
            <Link to="/Contactus" style={{ width: "100%" }}>
              <Button sx={{ ...styles.navButton, ...this.getLinkStyle('/Contactus') }}>
                {configJSON.contact}
              </Button>
            </Link>
            <Link className='navRequestServiceLink' to="/LogInPage" >
              <Box sx={{...styles.navRequestServiceBoxButton, ...this.getLinkStyle('/RequestService') }}>
                <Button sx={{ ...styles.navRequestServiceButton, ...this.getLinkStyle('/RequestService') }}>
                  {configJSON.requestService}
                  <Typography sx={styles.requestServiceBadge}>NEW</Typography>
                </Button>
              </Box>
            </Link>
            <Link to="/LogInPage" style={{ width: "100%" }}>
              <Button color="inherit" sx={{ ...styles.navButton, ...this.getLinkStyle('/LogInPage') }}>
                {configJSON.logIn}
              </Button>
            </Link>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.isDesktopScreen && (
          <Box component={"img"} src={otherPagesBanner} alt="Banner"
            sx={this.isShowBannerImage() ? styles.bannerImage : { display: "none" }} />
        )}
        <Box sx={styles.headerWrapper}>
          <AppBar position="static" sx={styles.headerAppBar}>
            <Toolbar sx={styles.toolbar}>
              <Box sx={styles.logoContainer}>
                <Box component="img" src={navLogo} alt="Logo" sx={styles.logoHeaderImage} />
                <Typography sx={styles.logoText}>
                  {configJSON.logoText}
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
          {!this.state.isDesktopScreen && (
            <>
              <AppBar position="static" sx={styles.navBar}>
                <Toolbar>
                  <Box sx={styles.menuIconsBox} onClick={this.handleNavToggle} data-test-id="hamburger-icon-button">
                    {this.state.isSideBarOpen ? <CloseIcon sx={styles.hamburgerCloseIcon}  /> : <MenuIcon sx={styles.hamburgerMenuIcon}  />}
                  </Box>
                </Toolbar>
              </AppBar>
              <Collapse in={this.state.isSideBarOpen}>
                <Box sx={styles.mobileSideBarWrapper} onClick={this.handleNavToggle} data-test-id="mobile-nav-menu">
                  {this.getNavLinks()}
                </Box>
              </Collapse>
            </>
          )}
          {this.state.isDesktopScreen && (
            <AppBar position="static" sx={styles.navBar}>
              <Toolbar sx={styles.navToolbar}>
                  <Box sx={styles.navigationLinkBox} onClick={this.handleNavToggle}>
                    {this.getNavLinks()}
                  </Box>
              </Toolbar>
            </AppBar>
          )}
        </Box>
        {!this.state.isDesktopScreen && (
          <Box component={"img"} src={otherPagesBanner} alt="Banner"
          sx={this.isShowBannerImage() ? styles.bannerImage : { display: "none" }} />
        )}
      </>
    );
  }
}
const styles = {
  bannerImage: {
    minHeight: '526px',
    width: '100%',
    objectFit: 'cover',
    "@media (max-width: 1036px)": {
      minHeight: '404px',
    },
    "@media (max-width: 500px)": {
      display: "none",
    }
  },
  headerWrapper: {
    width: "100%",
    position: "absolute",
    top: 0,
    zIndex: "99999999",
    "@media (max-width: 1036px)": {
      position: 'relative',
    }
  },
  mobileSideBarWrapper: {
    backgroundColor: '#3FB1CE',
    transition: 'height 0.5s ease',
    padding: '0 16px',
  },
  headerAppBar: {
    background: "rgba(0, 0, 0, 0.5)",
    height: '89.11px',
    zIndex: "999999",
    justifyContent: 'center',
    "@media (max-width: 1036px)": {
    height:"66px",
    background: "rgb(0, 0, 0)",
    }
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  logoHeaderImage:{
    width: "60px",
    height: "60px",
    "@media (max-width: 700px)": {
      width: "32px",
      height: "32px"
    },
  },
  logoText: {
    fontFamily: 'Oswald, sans-serif',
    fontWeight: 600,
    fontSize: '39px',
    lineHeight: '36px',
    "@media (max-width: 700px)": {
      fontSize: '20.8px',
      lineHeight: '19.2px',
    },
  },
  navBar: {
    background: '#3FB1CE',
    zIndex: "99999",
    justifyContent: "center",
    height: "55px",
    boxShadow: 'none',
  },
  navToolbar: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    height: 'auto',
    "@media (max-width: 1036px)": {
      display: "flex",
      justifyContent:"flex-start"
    }
  },
  navigationLinkBox:{
    "@media (max-width: 1036px)": {
      display:"flex",
      alignItems:"flex-start",
      flexWrap:"wrap",
      flexDirection:"column",
      width:"100%",
      mt:"5px",
      mb:"10px",
    }
  },
  navButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    ':hover': { backgroundColor: '#12629A', height: 'auto', borderRadius: "0px" },
    ':active': { backgroundColor: '#12629A', height: 'auto', borderRadius: "0px" },
    lineHeight: "29.12px",
    borderRadius: "0px",
    p: "13px 12px",
    display: "revert",
    textAlign: "left",
    fontSize: '18px',
    fontWeight: 700,
    color: '#FFFFFF',
    margin: '0',
    textDecoration: 'none',
    "@media (max-width: 1036px)": {
      p: "12.600px 18px",
      margin: '0px',
      width: "100%",
    }
  },
  activeLink: {
    backgroundColor: '#12629A'
  },
  navRequestServiceBoxButton:{
    display: "contents",
    "@media (max-width: 1036px)": {
      width: "100%",
      display: "inherit",
      height: "45px",
      paddingTop: "6px",
      ':hover': { backgroundColor: '#12629A' },
    }
  },
  navRequestServiceButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    ':hover': { backgroundColor: '#12629A' },
    ':active': { backgroundColor: '#12629A' },
    lineHeight: "42px",
    border: "1px solid #E5614B",
    boxShadow: "0px 0px 0px 2px #C69A93",
    borderRadius: "50px",
    p: "0px 12px",
    fontSize: '18px',
    fontWeight: 700,
    color: '#FFFFFF',
    margin: '0 10px', 
    textDecoration: 'none',
    position: "relative",
    "@media (max-width: 1036px)": {
      ':hover': { backgroundColor: '#3fb1ce' },
      ':active': { backgroundColor: '#3fb1ce' },
      background: "#3fb1ce",
      height:"38px",
    }
  },
  requestServiceBadge: {
    color: "#FFFFFF",
    backgroundColor: "#E5614B",
    borderRadius: "50px",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '11px',
    fontWeight: 600,
    p: "2px 7px",
    position: "absolute",
    top: "0px",
    right: "0px",
    transform: "translate(0px,-12px)"
  },
  hamburgerCloseIcon: {
    display: "none",
    "@media (max-width: 1036px)": {
      display: "block",
      fontSize: '25px',
      fontWeight: 800,
      m: "13px 0 3px 8px",
      stroke: "#DFDFDF",
      strokeWidth: "3px",
      cursor: "pointer",
    }
  },
  menuIconsBox: {},
  hamburgerMenuIcon: {
    display: "none",
    "@media (max-width: 1036px)": {
      display: "block",
      fontSize: '30px',
      fontWeight: 900,
      stroke: "#DFDFDF",
      strokeWidth: "1px",
      cursor: "pointer",
      ml: "7px",
    }
  },
};