import { BlockComponent } from "../../framework/src/BlockComponent";
import { IBlock } from "../..//framework/src/IBlock";
import { Message } from "../..//framework/src/Message";
import { runEngine } from "../..//framework/src/RunEngine";
import { getStorageData } from "../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export interface Props {
  showNotification: boolean
  setNotificationVisible: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  notificationList: Notification[];
  showNotification: boolean;
  userRole: string;
}

interface Notification {
  id: number;
  message: string;
  title: string;
  date: string;
  readed: boolean;
}

interface SS {
  id: any;
}

export default class AccountController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      userRole: '',
      showNotification: false,
      notificationList: [
        {
          id: 1,
          title: "New reading",
          message: "A new reading has been added",
          date: "5/13/2024, 5:43PM",
          readed: false
        },
        {
          id: 2,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: false
        },
        {
          id: 3,
          title: "New reading",
          message: "A new reading has been added",
          date: "5/13/2024, 5:43PM",
          readed: true
        },
        {
          id: 4,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: true
        },
        {
          id: 5,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: true
        },
        {
          id: 6,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: true
        }
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  setNotificationVisible = (show: boolean) => {
    this.setState({ showNotification: show })
  }

  componentDidMount = async () => {
    const userRole = await getStorageData('role');

    this.setState({ userRole });
  }

  // Customizable Area End
}
