import React from "react";
// Customizable Area Start
import { 
  Typography, 
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider
} from "@mui/material";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  leftArrowIcon,
  hotWaterIcon,
  coldWaterIcon,
} from "./assets";
// Customizable Area End

import MeterPageController, { Props, configJSON } from "./MeterPageController";
// Customizable Area Start
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import moment from "moment";


const renderTypeWithIcon = (meterType: 'Water' | 'Electric', waterMeterType?: 'Cold' | 'Hot') => {
  if (meterType === 'Electric') {
    return <Typography style={{ fontSize: '14px', fontFamily: "'Inter', sans-serif" }}>Electrical</Typography>;
  }

  if (waterMeterType === "Cold") {
    return (
      <>
        Water &nbsp;
        <div style={{ display: "flex", alignItems: "center" }}>
          (
          <img
            src={coldWaterIcon}
            alt="Cold Icon"
            style={{ width: "16px", height: "16px" }}
          />{" "}
          &nbsp;Cold)
        </div>
      </>
    );
  } else if (waterMeterType === "Hot") {
    return (
      <>
        Water &nbsp;
        <div style={{ display: "flex", alignItems: "center" }}>
          (
          <img
            src={hotWaterIcon}
            alt="Hot Icon"
            style={{ width: "16px", height: "16px" }}
          />{" "}
          &nbsp;Hot)
        </div>
      </>
    );
  }
};
// Customizable Area End
export default class MeterPage extends MeterPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.propertiesContainer}>
        <Box sx={webStyle.tenantTitleContainer}>
          <Box sx={webStyle.tenantActionContainer}>
            <button
              data-test-id="goBack"
              style={webStyle.backButton}
              onClick={() => {
                this.goBack();
              }}
            >
              <img src={leftArrowIcon} alt="" style={webStyle.btnIcon} />
              <Typography style={webStyle.typographyTitleText}>
                {configJSON.backToTenantText}
              </Typography>
            </button>
            <Typography sx={webStyle.tenantTitle}>
              {this.state.currentProperty.property_name}
            </Typography>
            <Typography sx={webStyle.tenantSubTitle}>
              {this.state.currentProperty.service_address}
            </Typography>
          </Box>
        </Box>
        <Box sx={webStyle.propertiesTableHeader}>
          <Box sx={webStyle.searchContainer}>
            <Box sx={webStyle.fieldControl}>
              <input
                style={webStyle.inputElemnt}
                data-test-id="search"
                type={"text"}
                placeholder={configJSON.searchPlaceholderText}
                onChange={(event) => {
                  this.searchProperties(event.target.value);
                }}
              />
              <img src={searchIcon} alt="" style={webStyle.searchIcon} />
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <button
              data-test-id="addMeter"
              style={webStyle.addPropertiesButton}
              onClick={() => {
                this.addMeter();
              }}
            >
              <img src={addIcon} alt="" style={webStyle.btnIcon} />
              <Typography style={webStyle.typographyTitleText}>
                {configJSON.addMeterText}
              </Typography>
            </button>
          </Box>
        </Box>
        <Box sx={webStyle.propertisTable}>
          <Box sx={webStyle.propertisTableHead}>
            <Typography style={webStyle.headerTitle}>
              {configJSON.meterNumberText}
            </Typography>
            <Typography style={webStyle.headerTitle}>
              {configJSON.typeText}
            </Typography>
            <Typography style={webStyle.headerTitle}>
              {configJSON.lastReadingText}
            </Typography>
            <Typography style={webStyle.headerTitle}>
              {configJSON.lastReadingDateText}
            </Typography>
            <Typography style={webStyle.headerTitleAction}>
              {configJSON.actionsText}
            </Typography>
          </Box>
          {this.state.meterList.map((meter, index) => (
            <Box sx={webStyle.propertisTableBody} key={meter.id}>
              <Box sx={webStyle.propertyItem}>{meter.meterNumber}.</Box>
              <Box sx={webStyle.propertyItem}>
                {renderTypeWithIcon(meter.type, meter.waterSubType)}
              </Box>
              <Box sx={webStyle.propertyItem}>{meter.lastRiding}</Box>
              <Box sx={webStyle.propertyItem}>{moment(meter.dateLastRiding).format("l")}</Box>
              <Box sx={webStyle.propertyItemAction}>
                <button
                  data-test-id={`editMeter.${index}`}
                  style={webStyle.editButton}
                  onClick={() => {
                    this.editMeter(meter.id);
                  }}
                >
                  <img src={editIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.editText}
                  </Typography>
                </button>
                <button
                  data-test-id={`deleteMeter.${index}`}
                  style={webStyle.deleteButton}
                  onClick={() => {
                    this.setDeleteButtonDialoge(meter.id);
                  }}
                >
                  <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.deleteText}
                  </Typography>
                </button>
              </Box>
            </Box>
          ))}
        </Box>
        <Dialog
          open={!!this.state.deleteButtonDialoge}
          onClose={()=> this.setDeleteButtonDialoge(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "633px", 
              height: "autp", 
              padding: "40px 32px 32px 32px", 
              gap: "10px", 
              borderRadius: "16px", 
              fontFamily:"'Inter', sans-serif",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={webStyle.dialogTitle}
          >
            {configJSON.dialogTitle}
          </DialogTitle>
          <DialogContent style={{ padding: "0px 20px"}}>
            <DialogContentText
              id="alert-dialog-description"
              style={webStyle.dialogContent}
            >
              {configJSON.dialogContent}
            </DialogContentText>
            <Divider /> 
          </DialogContent>
          <DialogActions style={webStyle.dialogAction}>
            <Button
              onClick={()=> this.setDeleteButtonDialoge(null)}
              variant="outlined" 
              sx={webStyle.cancelButton}
            >
              {configJSON.cancelText}
            </Button>
            <Button
              onClick={() => this.deleteMeter(this.state.deleteButtonDialoge)}
              sx={webStyle.confirmDelete}
              startIcon={<DeleteIcon />} 
            >
              {configJSON.deleteText}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(248, 250, 252, 1)",
    padding: "0px 30px",
    minHeight: "100%",
  },
  tenantTitleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "space-between",
  },
  propertiesTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tenantActionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: 'rgba(248, 250, 252, 1)',
    borderRadius: '12px',
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    '&:last-of-type': {
      borderRadius: '0 0 12px 12px',
    }
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  backButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 6px",
  },
  addPropertiesButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 10px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc( 25% - 45px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "180px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  propertyItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc( 25% - 45px)",
    wordBreak: 'break-word',
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  propertyItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "180px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tenantTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    color: "#0F172A",
    wordBreak: 'break-word',
  },
  tenantSubTitle: {
    marginTop: "10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    wordBreak: 'break-word',
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    marginLeft: "6px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  confirmDelete:{
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "8px",
    textTransform: "none",
    backgroundColor:"white",
    color:"#DC2626",
    margin: "10px 10px 0px 0px",
    border:"1px solid #DC2626",
 },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
    height:"40px",
    textTransform: "none",
  },
  dialogTitle: { 
    fontWeight: "600", 
    paddingBottom: "8px",
    fontFamily:"'Inter', sans-serif", 
    fontSize:'20px',
    whiteSpace:"nowrap"
  },
  dialogContent: { 
    color: "#64748B", 
    paddingBottom: "16px",
    fontFamily:"'Inter', sans-serif",
    fontSize:"18px"
  },
  dialogAction: {
     padding: "16px"
  },
};
// Customizable Area End
