import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { Report } from "./CustomerReports.web";
import { CustomerEditableTextField } from "./CustomerEditableTextField.web";

interface CustomerReportInvoiceProps {
  report: Report;
}

type Invoice = {
  serviceDescription: string;
  serviceRate: number;
  serviceQuantity: number;
  additionalChargesDescription: string;
  additionalChargesRate: number;
  additionalChargesQuantity: number;
};

export const CustomerReportInvoice = ({}: CustomerReportInvoiceProps) => {
  const [invoiceInfo, setInvoiceInfo] = useState<Invoice>({
    serviceDescription:
      "For meter reading service rendered at <blank>. Credit or debit card. Please add 4% service charge to invoice total.",
    serviceRate: 300,
    serviceQuantity: 1,
    additionalChargesDescription: "Credit card fee",
    additionalChargesRate: 4,
    additionalChargesQuantity: 1,
  });
  const serviceAmount = invoiceInfo.serviceRate * invoiceInfo.serviceQuantity;
  const additionalChargesAmount =
    (invoiceInfo.additionalChargesRate / 100) *
    serviceAmount *
    invoiceInfo.additionalChargesQuantity;
  const totalAmount = serviceAmount + additionalChargesAmount;

  const handleInvoiceChange = (
    field: keyof Invoice,
    value: string | number
  ) => {
    setInvoiceInfo({
      ...invoiceInfo,
      [field]: value,
    });
  };

  const formatCurrency = (value: string | number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  };

  const generateInvoice = () => {};

  return (
    <Box sx={webStyle.invoiceContainer}>
      <Box sx={webStyle.invoiceTableHeader}>
        <button
          style={webStyle.generateInvoiceButton}
          onClick={generateInvoice}
        >
          <Typography sx={webStyle.buttonText}>Generate invoice</Typography>
        </button>
      </Box>
      <Box />
      <Box sx={webStyle.invoiceTable}>
        <Box sx={webStyle.invoiceTableHead}>
          <Typography style={webStyle.headerTitle}>DESCRIPTION</Typography>
          <Typography style={webStyle.shortHeaderTitle}>RATE</Typography>
          <Typography style={webStyle.shortHeaderTitle}>QUANTITY</Typography>
          <Typography style={webStyle.shortHeaderTitle}>AMOUNT</Typography>
        </Box>
        <Box sx={webStyle.invoiceTableBody}>
          <Box sx={webStyle.invoceItem}>
            <CustomerEditableTextField
              initialValue={invoiceInfo.serviceDescription}
              onChange={(value) =>
                handleInvoiceChange("serviceDescription", value)
              }
              allowNumericOnly={false}
            />
          </Box>
          <Box sx={webStyle.shortInvoceItem}>
            <CustomerEditableTextField
              initialValue={invoiceInfo.serviceRate.toString()}
              onChange={(value) => handleInvoiceChange("serviceRate", value)}
              isMoneyValue
            />
          </Box>
          <Box sx={webStyle.shortInvoceItem}>
            <CustomerEditableTextField
              initialValue={invoiceInfo.serviceQuantity.toString()}
              onChange={(value) =>
                handleInvoiceChange("serviceQuantity", value)
              }
              allowNumericOnly
            />
          </Box>
          <Box sx={webStyle.shortInvoceItem} data-test-id="serviceAmount">
            {formatCurrency(serviceAmount)}
          </Box>
        </Box>
        <Box sx={webStyle.invoiceTableBody}>
          <Box sx={webStyle.invoceItem}>
            <CustomerEditableTextField
              initialValue={invoiceInfo.additionalChargesDescription}
              onChange={(value) =>
                handleInvoiceChange("additionalChargesDescription", value)
              }
              allowNumericOnly={false}
            />
          </Box>
          <Box sx={webStyle.shortInvoceItem}>
            <CustomerEditableTextField
              initialValue={invoiceInfo.additionalChargesRate.toString()}
              onChange={(value) =>
                handleInvoiceChange("additionalChargesRate", value)
              }
              isPercentageValue
              allowNumericOnly
            />
          </Box>
          <Box sx={webStyle.shortInvoceItem}>
            <CustomerEditableTextField
              initialValue={invoiceInfo.additionalChargesQuantity.toString()}
              onChange={(value) =>
                handleInvoiceChange("additionalChargesQuantity", value)
              }
              allowNumericOnly
            />
          </Box>
          <Box sx={webStyle.shortInvoceItem} data-test-id="additionalChargesAmount">
            {formatCurrency(additionalChargesAmount)}
          </Box>
        </Box>
      </Box>
      <Box sx={webStyle.totalWrapper}>
        <Typography sx={webStyle.totalText}>Total</Typography>
        <Typography sx={webStyle.totalText} data-test-id="totalAmount">
          {formatCurrency(totalAmount)}
        </Typography>
      </Box>
    </Box>
  );
};

const webStyle = {
  invoiceContainer: {
    display: "flex",
    flexDirection: "column",
  },
  invoiceTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  invoiceTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px 12px 0 12px",
  },
  invoiceTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: "#F8FAFC",
    borderRadius: "12px 12px 0 0",
  },
  invoiceTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 0 12px",
    },
  },
  generateInvoiceButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    height: "44px",
    alignItems: "center",
    margin: "10px 0 0 12px",
    padding: "0 16px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "55%",
    margin: "13px 4px",
  },
  shortHeaderTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "15%",
    margin: "13px 4px",
  },
  invoceItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "55%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  shortInvoceItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "15%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  totalWrapper: {
    display: "flex",
    width: "30%",
    justifyContent: "space-between",
    alignSelf: "flex-end",
    padding: "16px",
    borderLeft: "1px solid #CBD5E1",
    borderBottom: "1px solid #CBD5E1",
    borderRight: "1px solid #CBD5E1",
    borderRadius: "0 0 12px 12px",
  },
  totalText: {
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#0F172A",
  },
};
