import React, { Component } from "react";
import { Typography, Box, Link } from "@mui/material";
import { otherPagesMobileUiBanner } from "../../blocks/landingpage/src/assets";
export const baseURL = require("../../framework/src/config.js").baseURL;

export default class Testimonials extends Component {
    state: any = {
        getTestimonialsContentData: null,
        loading: true,
        error: null,
    };

    componentDidMount() {
        this.fetchTestimonialsContentData();
    }

    fetchTestimonialsContentData = async () => {
        try {
            const response = await fetch(`${baseURL}/bx_block_content_management/testimonials`);
            if (!response.ok) {
                throw new Error('Something went error');
            }
            const responseJson = await response.json();
            this.setState({
                getTestimonialsContentData: responseJson?.data[0]?.attributes || {},
                loading: false
            });
        } catch (error) {
            this.setState({ error: error as any, loading: false });
        }
    };
    render() {
        console.log({getTestimonialsContentData:this.state.getTestimonialsContentData});
        
        return (
            <>
                <Box component={"img"} className='otherPagesMobileUiBannerStyle'
                    src={otherPagesMobileUiBanner} alt="Banner_Img" />
                <Box sx={webStyle.testimonialsWrapper}>
                    <Box sx={webStyle.testimonialsHeader} data-test-id="title"
                        dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.title }}
                    ></Box>
                    <Box sx={webStyle.testimonialsDescription} data-test-id="content"
                        dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.content }}
                    ></Box>
                    <Box sx={webStyle.testimonialsBoxWrapper}>
                        <Box sx={webStyle.testimonialsBox}>
                            <Box sx={webStyle.testimonialsBoxText}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial1_title }}
                            ></Box>
                            <Box sx={webStyle.testimonialsName}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial1_person }}
                            ></Box>
                            <Box sx={webStyle.testimonialsCompanyName}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial1_company }}
                            ></Box>
                        </Box>
                    </Box>
                    <Box sx={webStyle.testimonialsBoxWrapper}>
                        <Box sx={webStyle.testimonialsBox}>
                            <Box sx=
                                {webStyle.testimonialsBoxText}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial2_title }}
                            ></Box>
                            <Box sx={webStyle.testimonialsName}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial2_person }}
                            ></Box>
                            <Box sx={webStyle.testimonialsCompanyName}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial2_company }}
                            ></Box>
                        </Box>
                    </Box>
                    <Box sx={webStyle.testimonialsBoxWrapper}>
                        <Box sx={webStyle.testimonialsBox}>
                            <Box sx={webStyle.testimonialsBoxText}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial3_title }}
                            ></Box>
                            <Box sx={webStyle.testimonialsName}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial3_person }}
                            ></Box>
                            <Box sx={webStyle.testimonialsCompanyName}
                                dangerouslySetInnerHTML={{ __html: this.state.getTestimonialsContentData?.testimonial3_company }}
                            ></Box>
                        </Box>
                    </Box>
                </Box>

            </>
        );
    }
}

const webStyle = {
    testimonialsWrapper: {
        p: "80px 99px",
        background: "#E4FAFF",
        "@media (max-width: 810px)": {
            p: "40px 16px"
        },
        a: {
            color: '#12629A !important'
        }
    },
    testimonialsText: {
        fontWeight: "700",
        lineHeight: "35.2px",
        fontSize: "30px",
        "@media (max-width: 810px)": {
            fontSize: "23px",
            lineHeight: "25.3px",
        }
    },
    testimonialsHeader: {
        color: "#12629A",
        fontFamily: "'Nunito Sans', sans-serif",
        fontWeight: "700",
        lineHeight: "35.2px",
        fontSize: "32px",
        "span": {
            fontWeight: "800",
        },
        "@media (max-width: 810px)": {
            fontSize: "23px",
            lineHeight: "25.3px",
            fontWeight: "900",
            "span": {
                fontWeight: "900",
            },
        }
    },
    testimonialsDescription: {
        fontFamily: "'Nunito Sans', sans-serif",
        fontWeight: "400",
        lineHeight: "29.12px",
        fontSize: "18px",
        margin: "16px 0",
        "> p:first-of-type > a": {
          fontWeight: "400",
          "&:hover": {
            textDecoration:"none"
          },
        },
        "> p:nth-of-type(2) > a": {
          fontWeight: "400",
          "&:hover": {
            textDecoration: "none" 
          },
        },
    },
    contactUsText: {
        fontFamily: "'Nunito Sans', sans-serif",
        fontWeight: "400",
        lineHeight: "29.12px",
        fontSize: "18px",
    },
    testimonialsBoxWrapper: {
        display: "grid",
        gap: "24px",
        mt: "18px",
    },
    testimonialsBox: {
        height: "fit-content",
        p: "22.4px 23px 23px",
        border: "8px solid #12629A",
        background: "linear-gradient(#61C2FF, #299BE3)",
    },
    testimonialsBoxText: {
        fontFamily: "'Nunito Sans', sans-serif",
        fontWeight: "400",
        lineHeight: "29.12px",
        fontSize: "18px",
        color: "#FFFFFF",
        fontStyle: "italic",
        textShadow: "0px 0px 3px rgba(18, 98, 154, 0.82)",
        "> p": {
            marginTop: "0px",
        },
    },
    testimonialsName: {
        fontFamily: "'Nunito Sans', sans-serif",
        fontWeight: "600",
        lineHeight: "29.12px",
        fontSize: "18px",
        color: "#FFFFFF",
        mt: "14px",
        textShadow: "0px 0px 3px rgba(18, 98, 154, 0.82)",
    },
    testimonialsCompanyName: {
        fontFamily: "'Nunito Sans', sans-serif",
        fontWeight: "400",
        lineHeight: "29.12px",
        fontSize: "18px",
        color: "#FFFFFF",
        textShadow: "0px 0px 3px rgba(18, 98, 154, 0.82)",
    },
};
