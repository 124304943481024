Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";


exports.companyNameText = "Company name";
exports.serviceNeededText = "Service Needed";
exports.commentText = "Comment";
exports.createdAtText = "Created At";
exports.statusText = "Status";
exports.actionsText = "Actions";

exports.contactFormNameText = "Name";
exports.contactFormEmailText = "Email";
exports.contactFormPhoneText = "Phone";
exports.contactFormServiceNeededText = "Service Needed";
exports.contactFormCommentText = "Comment";
exports.contactFormCreatedAtText = "Created At";
exports.contactFormActionsText = "Actions";

exports.supportText = "Support";
exports.noDataTitleText = "No data";
exports.searchPlaceholderText = "Search";

exports.showText = "Show";
exports.viewDetailsText = "View details";
exports.deleteText = "Delete";

exports.dateText = "Date";

exports.lastYearText = "Last 12 months";      
exports.previusYearText = "Previous year (2023)";    
exports.currentYearText = "2024";
// Customizable Area End
