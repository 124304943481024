Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.supportTitleText = "Support";
exports.servicesNeededText = "Services needed";
exports.messageText = "Message";
exports.sendText = "Send";

// exports.confirmPasswordPlaceholderText
// Customizable Area End
