import React, { Component, createRef, RefObject } from 'react';
import { Box, Typography, Fade, Link } from '@mui/material';
import { styled } from '@mui/system';
import PhoneIcon from '@mui/icons-material/Phone';
import { machine,footer} from "../../blocks/landingpage/src/assets";
import { Link as RouterLink} from 'react-router-dom';
import { config } from 'process';
import { servicesSectionBanner } from '../../blocks/landingpage/src/assets';
export const configJSON = require("../../blocks/landingpage/src/config");

interface AppFooterState {
  backgroundPosition: string;
  areReadingsInView: boolean;
}
export default class AppFooter extends Component<{}, AppFooterState> {
  constructor(props: {}){
    super(props);
    this.state = {
      backgroundPosition: "50% -174.3px",
      areReadingsInView: false,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    this.readingsRef = React.createRef();
  }

  getLinkStyle(path: string) {
    return location.pathname === path ? styles.activeLink : {};
  }

  readingsRef: React.RefObject<HTMLDivElement>;
  observer: IntersectionObserver;

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this.observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        this.setState({ areReadingsInView: entry.isIntersecting });
      },
      { threshold: 0.1 }
    );
    if (this.readingsRef.current) {
      this.observer.observe(this.readingsRef.current);
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    if (this.readingsRef.current) {
      this.observer.unobserve(this.readingsRef.current);
    }
  }

  handleScroll = () => {
    const scrollTop = window.scrollY / 2.5;

    const basePosition = -174.3;
    const backgroundMovementScrollSpeed = 0.4;

    const newPosition = basePosition - scrollTop * backgroundMovementScrollSpeed;
    const backgroundPosition = `50% ${newPosition}px`;

    this.setState({ backgroundPosition });
  };

  scrollToTop = ()=>{
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Box className="footer" sx={styles.footerContainer}>
          <Box className="readings" sx={{...styles.topSection, ...(this.state.areReadingsInView ? styles.topSectionInView : {})}}
            style={{backgroundPosition: this.state.backgroundPosition,
            display: location.pathname === '/Contactus' ? 'none' : 'block',}}
            ref={this.readingsRef}
          >
            <Typography sx={styles.mainText}>
            {configJSON.mainText}
            </Typography>
            <Link href={`tel:+${configJSON.phoneNumber}`} style={styles.callText}>
            {configJSON.callText}
            </Link>
          </Box>
        <Box className="help-give" sx={styles.middleSection}>
          <Typography sx={styles.middleText}>
            {configJSON.middleText}
          </Typography>
          <Typography className="phone-number"sx={styles.phoneText}>
          <Typography sx={styles.footerPhoneNumber}>
          <PhoneIcon sx={styles.phoneIcon} />
          <Link href={`tel:+${configJSON.phoneNumber}`}  sx={styles.phoneNumberLink}>
            {configJSON.phoneNumber}
          </Link>
        </Typography>
          <Typography sx={styles.footerPhoneNumber}>
          <PhoneIcon sx={styles.phoneIcon} />
          <Link href={`tel:+${configJSON.phoneNumberTwo}`} sx={styles.phoneNumberLink}>
            {configJSON.phoneNumberTwo}
          </Link>
        </Typography>
          </Typography>
        </Box>

        <Box className="footer-info" sx={styles.bottomSection}>
          <Box sx={styles.infoContainer}>
              <Box sx={styles.infoBox}>
                <Typography variant="h6" sx={styles.infoHeader}>INFO</Typography>
                <Box sx={styles.links}>
                <StyledRouterLink to="/" style={{  ...this.getLinkStyle('/') }} onClick={this.scrollToTop}>
                    {configJSON.home}
                  </StyledRouterLink>
                  <StyledRouterLink to="/Aboutus" style={{...this.getLinkStyle('/Aboutus') }}   onClick={this.scrollToTop}>
                    {configJSON.about}
                  </StyledRouterLink>
                  <StyledRouterLink to="/Services" style={{ ...this.getLinkStyle('/Services') }} onClick={this.scrollToTop}>
                    {configJSON.service}
                  </StyledRouterLink>
                  <StyledRouterLink to="/Testimonials" style={{...this.getLinkStyle('/Testimonials') }}  onClick={this.scrollToTop}>
                    {configJSON.testimonial}
                  </StyledRouterLink>
                  <StyledRouterLink to="/Contactus" style={{...this.getLinkStyle('/Contactus') }} onClick={this.scrollToTop}>
                    {configJSON.contact}
                  </StyledRouterLink>
                </Box>
              </Box>
            
              <Box sx={styles.serviceBox}>
                <Typography sx={styles.serviceTitle}>
                  {configJSON.serviceTitle}
                </Typography>
                <Box>
                  <Typography sx={styles.serviceText}>
                    {configJSON.serviceText}
                  </Typography>
                  <Typography sx={styles.serviceText2}>
                  <Link href="" sx={{...styles.link, textDecoration: "none"}}>{configJSON.serviceTextTwo}</Link>
                  </Typography>
                </Box>
                <Typography sx={styles.hoursHeader}>
                {configJSON.hoursHeader}
                </Typography>
                <Box>
                  <Typography sx={styles.hoursText}>
                  {configJSON.hourstext}
                  </Typography>
                  <Typography sx={styles.hoursText}>
                  {configJSON.hoursTextTwo}
                  </Typography>
                  <Typography sx={styles.hoursText}>
                  {configJSON.hourTextThree}
                  </Typography>
              </Box>
            </Box>

            <Box sx={styles.imageBox}>
              <img
                style={styles.image}
                src={machine}
                alt="machine"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.footer}>
          <Typography sx={styles.footerText}>
           {configJSON.footerText1}<b>{configJSON.footerText2}</b>{configJSON.footerText3}<Typography component="span" sx={styles.appFooterPipe}>|</Typography>{configJSON.footerText4}
          </Typography>
        </Box>
      </Box>
    );
  }
}
const StyledRouterLink = styled(RouterLink)({
  color: 'white',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '25.89px',
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: '400',
  textDecoration: 'none',
  '&:hover': {
    color: '#3FB1CE', // Hover color
  },
  '&.active': {
    fontWeight: 'bold',
    color: '#3FB1CE', // Active color
  }
});
const styles = {
  footerContainer: {
    backgroundColor: '#E4FAFF',
  },
  topSection: {
    position: "relative",
    backgroundImage:`url(${servicesSectionBanner})`,
    backgroundColor: "#1d8dbc",
    backgroundRepeat: "no-repeat",
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '82px 0',
    opacity: 0,
    transform: 'translateY(200px)',
    transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    "@media (max-width: 767px)": {
      backgroundSize: "cover",
      height: 'auto',
      padding: '80px 8px',
    },
  },
  topSectionInView: {
    opacity: 1,
    transform: 'translateY(1)'
  },
  mainText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '30px',
    fontWeight: 400,
    '@media (max-width: 767px)': {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: "26.4px"
    }
  },
  callText: {
    fontFamily: "'Nunito Sans', sans-serif",
    textDecoration:"none",
    fontWeight: 900,
    fontSize: '30px',
    color: '#FFFFFF',
    mt: "16px",
    '@media (max-width: 767px)': {
      fontSize: '24px',
      lineHeight: "26.4px",
      fontWeight: 900,
    }
  },
  middleSection: {
    height: 'auto',
    backgroundColor: '#E5614B',
    color: 'white',
    padding: '30px 99px',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: '9999',
    '@media (max-width: 780px)': {
      padding: '30px 16px',
      textAlign: 'center',
      flexDirection: 'column',
      gap: "16px"
    },
  },
  middleText: {
    fontWeight: 800,
    color: '#FFFFFF',
    fontFamily: "'Nunito Sans', sans-serif",
    width:"75%",
    lineHeight:"35px",
    fontSize: "27px",
    display: 'flex',
    maxWidth: "900px",
    alignItems: 'center',
    '@media (max-width: 780px)': {
      width: '100%',
      fontSize: "18px",
      fontWeight: 800,
      lineHeight:"23.4px"
    },
  },
  phoneText: {
    fontWeight: 800,
    color: '#000000',
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '27px',
    lineHeight:"0",
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 810px)' : {
      alignItems: "center",
      fontSize: "33px",
      fontWeight: "800",
      gap: "0px",
    }
  },
  phoneIcon: {
    color: '#000000',
    verticalAlign: 'middle',
    fontWeight: 800,
    fontSize: '33px',
    mt:"4px",
    stroke: "black"
  },
  footerPhoneNumber:{
    fontFamily: "'Nunito Sans', sans-serif",
    display:"flex", 
    fontSize:"33px",
    fontWeight:"800",
    gap:"10px",
  },
  phoneNumberLink:{
    color: 'black', 
    textDecoration: 'none'
  },
  bottomSection: {
    padding: '40px 99px 10px 99px',
    backgroundImage: `url(${footer})`, 
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 810px)': {
      padding: '60px 16px 22px 16px',
      height: 'auto',
      backgroundPosition: 'right',
    },
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)': {
      padding: '50px 30px'
    }
  },
  infoContainer: {
    display: 'flex',
    justifyContent:"space-between",
    '@media (max-width: 650px)': {
      width: '100%',
      height: 'auto',
      flexWrap:"wrap"
    }
  },
  infoBox: {
    width: '230px',
    height: '210px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    '@media (max-width: 920px)': {
      display: "none"
    }

  },
  infoHeader: {
    color: 'white',
    marginBottom: '10px',
    fontWeight:"600",
    fontSize:"28px",
    lineHeight:"30.8px",
    fontFamily: "'Nunito Sans', sans-serif",
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
link: {
    color: 'white',
    cursor: 'pointer',
    fontSize:"16px",
    lineHeight: "25.89px",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight : "400",
    textDecoration:"none",
    ':hover': {
      color: '#3FB1CE',
    },
  },
  activeLink: {
    fontWeight: '400',
    color: '#3FB1CE',
  },
  serviceBox: {
    width: '650px',
    height: '284.97px',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    '@media (max-width: 650px)': {
      width: '100%',
      height: 'auto',
      flexWrap: "wrap",
      gap: "16px",
      textAlign:"center"
    }
  },
  serviceTitle: {
    fontSize: '28px',
    fontWeight: 400,
    lineHeight:"30.8px",
    fontFamily: "'Nunito Sans', sans-serif",
    '@media (max-width: 650px)': {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight:"24.2px",
    }
  },
  serviceText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: "25.89px",
    fontFamily: "'Nunito Sans', sans-serif",
    mt:"12px",
    '@media (max-width: 650px)': {
      mt: "5px"
    }
  },
  serviceText2: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: "25.89px",
    fontFamily: "'Nunito Sans', sans-serif",
    mt:"5px",
    '@media (max-width: 650px)': {
      mt: "16px"
    }
  },
  hoursHeader: {
    fontWeight:"600",
    fontSize:"28px",
    color: '#FFFFFF',
    lineHeight: "30.8px",
    mt: "26px",
    fontFamily: "'Nunito Sans', sans-serif",
    '@media (max-width: 650px)': {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight:"24.2px",
      mt: "16px"
    }
  },
  hoursText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: "25.89px",
    fontFamily: "'Nunito Sans', sans-serif",
  },
  imageBox: {
    height: '305px',
    width: '300px',
    '@media (max-width: 650px)': {
      display:"none",
    }
  },
  image: {
    width: '315px',
    height: '310px',
  },
  footer: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    padding: '10px',
    textAlign: 'center',
    '@media (max-width: 810px)': {
      p: "15px 16px"
    }
  },
  footerText: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight:"21px",
    '@media (max-width: 768px)': {
      fontSize: '12px',
    }
  },
  appFooterPipe: {
    '@media (max-width: 768px)': {
      display: "none"
    }
  }
};

