Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getPropertiesApiEndPoint = "bx_block_custom_forms/properties";
exports.getPropertiesApiMethod = "Get";
exports.getPropertiesApiContentType = "application/json";

exports.deletePropertiesApiEndPoint = "bx_block_custom_forms/properties";
exports.deletePropertiesApiMethod = "DELETE"
exports.deletePropertiesApiContentType = "application/json";

exports.noPropertyTitleText = "No properties";
exports.noPropertySubTitleText = "Click on the button below to start adding your properties";
exports.searchPlaceholderText = "Search";
exports.filterText = "Filter";
exports.addPropertyText = "Add property";
exports.propertyNameText = "Property Name";
exports.addressText = "Address";
exports.numberOfTenantsText = "# of Tenants";
exports.numberOfMetersText = "# of Meters";
exports.actionsText = "Actions";
exports.addTenantText = "Add tenant";
exports.editText = "Edit";
exports.deleteText = "Delete";
exports.cancelText = "Cancel";
exports.dialogTitle ="Are you sure you want to delete this property?";
exports.dialogContent = "This action can’t be undone, and all the data associated with this property will be permanently deleted and no longer accessible.";
// Customizable Area End