import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  scrollTop: number;
  backgroundPosition: string,
  scrollContainerRef:any,
  isExpanded: boolean,
  homePageContentData: any,
  isImageLoadFailed: boolean,
  getHomePageContentDataId: string,
  readMoreContent1Text: string;
  alwaysVisibleContent1Text: string;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      scrollTop: 0,
      backgroundPosition: "50% -348.6px",
      scrollContainerRef: React.createRef<HTMLDivElement>(),
      isExpanded : false,
      isImageLoadFailed: false,
      homePageContentData: {},
      getHomePageContentDataId: "",
      readMoreContent1Text: "",
      alwaysVisibleContent1Text: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        const homePageContentData = responseJson?.data[0]?.attributes || {};
        const content1TextSplit = (homePageContentData?.content1_text || '').split('</p>') || [];
        this.setState({ homePageContentData, readMoreContent1Text: content1TextSplit.slice(0, 2).join('</p>'), alwaysVisibleContent1Text: content1TextSplit.slice(2).join('</p>') });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.getHomePageContentData()
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.scrollY;

    const basePosition = -348.6;
    const backgroundMovementScrollSpeed = 0.2;

    const newPosition = basePosition + scrollTop * backgroundMovementScrollSpeed;
    const backgroundPosition = `50% ${newPosition}px`;

    this.setState({ backgroundPosition });
  };

  handleReadLessMoreToggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }

  getHomePageContentData = async () => {
    // const token = await storage.get("loginToken");
    // const header = { token };

     const requestMessage =  new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      getHomePageContentDataId: requestMessage.messageId
    })
    //  this.getHomePageContentDataId =  requestMessage.messageId;

     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/home`
    );

    // getLoginRecentJobsMsg.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

     runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
