import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reports {
  id: string;
  tenant: string;
  meter: string;
  consumption: string;
  date: string;
}

interface CustomerApp {
  id: number;
  companyName: string;
  serviceNeeded: string;
  comment: string;
  createdAt: string;
  status: CustomerAppStatus;
}

interface ContactForm {
  id: number;
  name: string;
  email: string;
  phone: string;
  serviceNeeded: string;
  comment: string;
  createdAt: string;
}

interface StatusModal {
  statusModal: number | null;
  x: number;
  y: number;
}

interface S {
  step: number;
  modal: StatusModal;
  itemsPerPage:number;
  showCustomerAppModal: number | null;
  showContactFormModal: number | null;
  customerAppList: CustomerApp[];
  currentCustomerAppList: CustomerApp[];
  contactFormList: ContactForm[];
  currentContactFormList: ContactForm[]
  customerAppFilter: number;

  customerAppCurrentPage:number;
  customerAppTotalPages:number;
  customerAppFirstItemIndex:number,
  customerAppLastItemIdex:number,

  contactFormCurrentPage:number;
  contactFormTotalPages:number;
  contactFormFirstItemIndex:number,
  contactFormLastItemIdex:number,
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum CustomerAppStatus {
  Processing = "Processing",
  Processed = "Processed",
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class AdminSupportPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      step: 1,
      modal: {
        statusModal: null,
        x: 0,
        y: 0,
      },
      customerAppCurrentPage: 1,
      customerAppTotalPages: 1,
      customerAppFirstItemIndex: 1,
      customerAppLastItemIdex: 10,
      contactFormCurrentPage: 1,
      contactFormTotalPages: 1,
      contactFormFirstItemIndex: 1,
      contactFormLastItemIdex: 10,
      showCustomerAppModal: null,
      showContactFormModal: null,
      customerAppList: [{
          id: 1,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processing,
        }, {
          id: 2,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processing,
        }, {
          id: 3,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processed,
        }, {
          id: 4,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processed,
        }, {
          id: 5,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processing,
        }, {
          id:6,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processed,
        } ,{
          id: 7,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processing,
        }, {
          id: 8,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processed,
        }, {
          id: 9,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processing,
        }, {
          id:10,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processed,
        } ,{
          id: 11,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processing,
        }, {
          id: 12,
          companyName: "Acme group.",
          serviceNeeded: "Electricity",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
          status: CustomerAppStatus.Processed,
        }
      ],
      itemsPerPage:10,
      currentCustomerAppList: [],
      currentContactFormList: [],
      contactFormList: [{
          id: 1,
          name: "Acme group.",
          email: "sabren@comcast.net",
          phone: "(603) 555-0123",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 2,
          name: "Acme group.",
          email: "kspiteri@live.com",
          phone: "(225) 555-0118",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 3,
          name: "Acme group.",
          email: "bockelboy@att.net",
          phone: "(316) 555-0116",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 4,
          name: "Acme group.",
          email: "adamk@yahoo.com",
          phone: "(207) 555-0119",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 5,
          name: "Acme group.",
          email: "sumdumass@gmail.com",
          phone: "(201) 555-0124",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 6,
          name: "Acme group.",
          email: "doormat@att.net",
          phone: "(808) 555-0111",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        },
        {
          id: 7,
          name: "Acme group.",
          email: "sabren@comcast.net",
          phone: "(603) 555-0123",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 8,
          name: "Acme group.",
          email: "kspiteri@live.com",
          phone: "(225) 555-0118",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 9,
          name: "Acme group.",
          email: "bockelboy@att.net",
          phone: "(316) 555-0116",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 10,
          name: "Acme group.",
          email: "adamk@yahoo.com",
          phone: "(207) 555-0119",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 11,
          name: "Acme group.",
          email: "sumdumass@gmail.com",
          phone: "(201) 555-0124",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }, {
          id: 12,
          name: "Acme group.",
          email: "doormat@att.net",
          phone: "(808) 555-0111",
          serviceNeeded: "Electricity Reading",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum tortor ac laoreet venenatis. Sed mi eros, congue eu luctus nec, porta auctor orci. Sed at eros placerat, dignissim odio eu, convallis magna. Nullam magna nulla, lacinia vitae sem ac, vestibulum pretium neque. Nulla est arcu, lacinia vel lacinia vitae,",
          createdAt: "7/11/2023",
        }
      ],
      customerAppFilter: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchCustomerApp = (search: string) => {
    console.log({ search });
  };

  setStep = (step: number) => {
    this.setState({ step });
  }

  closeModals = () => {
    this.setState({ showContactFormModal: null, showCustomerAppModal: null });
  }

  openContactFormModal = (id: number) => {
    this.setState({ showContactFormModal: id });
  }

  openCustomerAppModal = (id: number) => {
    this.setState({ showCustomerAppModal: id });
  }

  openStatusModalWindow = (statusModal: number | null, x?: number, y?: number) => {
    this.setState({ modal: { 
        statusModal, 
        x: x || 0, 
        y: y || 0,
      } 
    })
  }

  chnageCustomerAppStatus = (id: number, status: CustomerAppStatus) => {
    const newCustomerApp = this.state.customerAppList;
    newCustomerApp.forEach(app => {
      if (app.id === id) {
        app.status = status
      }
    })
    this.setState({ customerAppList: newCustomerApp, modal: { statusModal: null, x: 0, y: 0 } });
  }

  deleteCustomeerApp = (id: number) => {
    console.log({ id });
  }

  changeCustomerAppFilter = (id: number) => {
    this.setState({ customerAppFilter: id });
  }

  handleNextCustomerAppPage = () => {
    if (this.state.customerAppCurrentPage < Math.ceil(this.state.customerAppList.length / this.state.itemsPerPage)) {
      this.setState({ customerAppCurrentPage: this.state.customerAppCurrentPage + 1 });
    }
  };

  handlePrevCustomerAppPage = () => {
    if (this.state.customerAppCurrentPage > 1) {
      this.setState({ customerAppCurrentPage: this.state.customerAppCurrentPage - 1 });
    }
  };

  handleCustomerAppPageChange = (pageNumber: number) => {
    this.setState({ customerAppCurrentPage: pageNumber });
  };

  updateCustomerAppPagination = () => {
    const { customerAppCurrentPage: currentPage, itemsPerPage, customerAppList } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCustomerAppList = customerAppList.slice(indexOfFirstItem, indexOfLastItem);
    console.log({ currentPage, itemsPerPage, indexOfFirstItem, currentCustomerAppList });
    const totalPages = Math.ceil(customerAppList.length / itemsPerPage) || 1;

    if (currentCustomerAppList.length === 0) {
      this.handleCustomerAppPageChange(totalPages);
    }

    if (indexOfLastItem > customerAppList.length ) {
        indexOfLastItem = customerAppList.length;
    }

    this.setState({
      currentCustomerAppList,
      customerAppTotalPages: totalPages,
      customerAppFirstItemIndex:indexOfFirstItem,
      customerAppLastItemIdex:indexOfLastItem,  
    });
  };

  handleNextContactFormPage = () => {
    if (this.state.contactFormCurrentPage < Math.ceil(this.state.contactFormList.length / this.state.itemsPerPage)) {
      this.setState({ contactFormCurrentPage: this.state.contactFormCurrentPage + 1 });
    }
  };

  handlePrevContactFormPage = () => {
    if (this.state.contactFormCurrentPage > 1) {
      this.setState({ contactFormCurrentPage: this.state.contactFormCurrentPage - 1 });
    }
  };

  handleContactFormPageChange = (pageNumber: number) => {
    this.setState({ contactFormCurrentPage: pageNumber });
  };

  updateContactFormPagination = () => {
    const { contactFormCurrentPage: currentPage, itemsPerPage, contactFormList } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentContactFormList = contactFormList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(contactFormList.length / itemsPerPage) || 1;

    if (currentContactFormList.length === 0) {
      this.handleContactFormPageChange(totalPages);
    }

    if (indexOfLastItem > contactFormList.length ) {
        indexOfLastItem = contactFormList.length;
    }

    this.setState({
      currentContactFormList,
      contactFormTotalPages: totalPages,
      contactFormFirstItemIndex: indexOfFirstItem,
      contactFormLastItemIdex: indexOfLastItem,
    });
  };
  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "admin") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }

    await Promise.all([this.updateCustomerAppPagination()]);
    await Promise.all([this.updateContactFormPagination()]);
  };

  componentDidUpdate = async (prevProps: Props, prevState: S) => {
    if (    
      prevState.customerAppCurrentPage !== this.state.customerAppCurrentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.customerAppList !== this.state.customerAppList
      ) {
      await Promise.all([ this.updateCustomerAppPagination()]);
    }

    if (    
      prevState.contactFormCurrentPage !== this.state.contactFormCurrentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.contactFormList !== this.state.contactFormList
      ) {
      await Promise.all([ this.updateContactFormPagination()]);
    }
  }
  // Customizable Area End
}
