Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.logoText = "THE METER READING SERVICES"
exports.resetPasswordText = "Reset password";
exports.heyUserText = "Hey Jack,";
exports.needToResetYourPasswordText = "Need to reset your password? No problem! Just click the button below and you’ll be on your way.";
exports.ignoreIfyouDontMakeeRequestText = "If you did not make this request, please ignore this email";
exports.copyrightText = "© 2024 The Meter Reading Service. All Rights Reserved.";
// Customizable Area End