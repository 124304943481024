Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.invoiceButtonText = "Invoice";
exports.reportButtonText = "Report";
exports.headingText = "Field representatives";
exports.firstNameText = "FIRST NAME";
exports.lastNameText = "LAST NAME";
exports.lastLogInText = "LAST LOGIN";
exports.typeText = "ENABLED";
exports.statusText = "STATUS";
exports.actionsText = "Actions";
exports.noReportTitleText = "No reports";
exports.searchPlaceholderText = "Search";
// Customizable Area End
