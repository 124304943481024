Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.learnMore = "LEARN MORE";
exports.getStarted = "GET STARTED NOW";
exports.number = "718-756-4123";
exports.request = "Request Service";
exports.meterReading = "METER READING SERVICE";
exports.boxDiscription = "Give tenants verifiable accounts of their utility usage with our meter reading services.Let us eliminate inaccurate. Overstimated bills.";
exports.customized = "CUSTOMIZED TENANT INVOICES";
exports.boxDiscriptionSec = " We offer personalized invoicing for each client we serve. We also inform our customers of all leakages or other fixture issues.";
exports.inspection = "INSPECTION REPORTS";
exports.boxDiscriptionThird = "Gain itemized summaries of utility usage for all main and sub-meters on your property. We offer reports on a monthly or quarterly basis.";
exports.clientTestimonial = "CLIENT TESTIMONIALS";
exports.boxDiscriptionFour = "Find out what clients are saying about us! Our dedicated staff is proud to offer all of our clients prompt outstanding service.";
exports.meterHeading = "METER READING SERVICES IN BROOKLYN, NY";
exports.comitted = "COMMITTED TO OUR CUSTOMERS";
exports.comitmentDiscription = "At our reputable meter reading company, we aim to build synergistic relationships with our customer base because we want them to succeed through our service. Just some of the things we offer inspection reports, verifying accuracy of city bills and also meter reading services and more. We’re confident that will find what you are looking for with our services because we stand behind our work. Feel free to browse our site to learn more about our services. If you have any questions or concerns, please don’t hesitate to contact us at any time. We look forward to hearing from you soon."
exports.missionTittle = "THE METER READING SERVICE";
exports.missionSubTittle = "Mission Statement";
exports.missionDiscription = "To provide accurate meter reading service to Building Owners,Property Management Companies and Commercial business throughout New York Metropolitan Area.";
exports.customersTitle = "SOME OF OUR CUSTOMERS";;
exports.customerList = "Abeco Management Corp";
exports.customerListOne = "Akam Living Services";
exports.customerListTwo = "BCB Property Management";
exports.customerListThree = "Charles H. Greenthal Corp";
exports.boxDiscriptionFour = "Comjem Associates";
exports.customerListFive = "First Class Management";
exports.customerListSix = "George Butsikaris Realty Inc";
exports.customerListSeven = "Gorjian Real Estate Group";
exports.customerListEight = "Jack Resnick & Son";
exports.customerListNine = "Kaufman Organization";
exports.customerListTen = "Lemle & Wolff, Inc";
exports.customerListEleven = "Livingston Management";
exports.customerListTwelve = "Newmark Knight";
exports.customerListThirteen = "Orsid Realty Corporation";
exports.customerListFourteen = "Parkoff Management Organization";
exports.customerListFifteen = "SL Green Realty Corp";
exports.logoText = "THE METER READING SERVICES CORP.";
exports.home = "Home";
exports.about = "About Us";
exports.service = "Services";
exports.testimonial = "Testimonials";
exports.contact = "Contact";
exports.requestService = "Request Service";
exports.logIn = "Log In";
exports.mainText = "Accurate water, electrical, and gas meter readings.";
exports.callText = "CALL (718) 756-4123";
exports.middleText = "WHENEVER YOU NEED A METER TO BE READ, WE'RE HERE TO  HELP. GIVE US A CALL AND WE’LL TAKE CARE OF THE REST.";
exports.phoneNumber = "718.756.4123";
exports.phoneNumberTwo = "212.673.8920";
exports.serviceTitle = "METER READING SERVICE";
exports.serviceText = "1056 E 103rd St, Brooklyn, NY, 11236";
exports.serviceTextTwo = "info@meterreading.nyc";
exports.hoursHeader = "AVAILABLE HOURS";
exports.hourstext = "Monday – Friday: 9:00 AM – 5:00 PM";
exports.hoursTextTwo = "Saturday, Sunday: Closed";
exports.hourTextThree = "Holidays: Closed";
exports.footerText1 = " © 2024 ";
exports.footerText2 = "The Meter Reading Service.";
exports.footerText3 = " All Rights Reserved. ";
exports.footerText4 = "  Sitemap";
exports.btnExampleTitle = "CLICK ME";
exports.serviceDescription = " Utility companies come in various shapes and sizes, but they all strive for the same result - to deliver energy and water to theircustomers in an efficient, safe manner. That’s where our meter reading services in Brooklyn, NY and surrounding areas come in.Whether you’re a manager or a property owner, it’s essential that you always"
exports.servicesLink = "receive accurate readings"
exports.servicesInfo = "to ensure you or your tenants know precisely what's due "
exports.servicesInfo2 = "for the resources used, we make sure we are verifying accuracy of the city bills. At The Meter Reading Services Corp, we've made it our mission to deliver meter readings services to Brooklyn, NY and surrounding areas. Our services will provide you with accurate assessments at reasonable prices."
exports.servicesInfo3 = "Our company, previously known as Water Overflow Preventative, Inc., has proudly served the New York Metropolitan area since 1889. As Meter Reading Services has advanced, we've made advances in kind. However, our commitment to providing accurate, reliable services to businesses and individuals has stayed constant. Today, our highly motivated staff members read meters and deliver assessment solutions that allow our customers to move forward with their operations and everyday lives. Whenever you need meter reading services, we're here to help. Simply give us a call, and we'll take care of the rest. "
exports.serviceLink2 = "Read more"
exports.contactLink = "Contact"
exports.descriptionFive = "Find out what clients are saying about us! Our dedicated staff is proud to offer all of our clients prompt outstanding service."
exports.contactDescription = "our meter reading company for accurate assessments and reliable customer service. Based in Brooklyn, we proudly serve property owners throughout the New York Metropolitan Area."
// Customizable Area End