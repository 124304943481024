import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip,IconButton } from "@mui/material";
import * as Yup from "yup";

import { Formik } from "formik";
import { banner, passwordHiddenIcon, navLogo, errorIcon } from "./assets";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// Customizable Area End

import ResetPasswordPageController, {
  Props,
  configJSON,
} from "./ResetPasswordPageController";
// Customizable Area Start

// Customizable Area End
export default class ResetPasswordPage extends ResetPasswordPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[\w!@#$%^&*(),.?":{}|<>]{8,72}$/,
          'Password must include at least one uppercase letter, one lowercase letter, one number, one special character, be at least 8 characters long, and have equal or less than 72 characters'
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.resetPasswordContainer}>
        <Box sx={webStyle.leftBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.resetPasswordFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>
                {configJSON.resetPasswordText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.setNewPasswordText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
              }}
              data-test-id="ResetPasswordForm"
            >
              {({ errors,touched, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"password"}>
                        {configJSON.passwordText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...(touched.password && errors.password ? webStyle.inputElementError : {})}}
                        data-test-id="password"
                        type={this.state.showPassowrd ? "text" : "password"}
                        placeholder={configJSON.passwordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password", event.target.value);
                          this.setPassword(event.target.value)
                        }}
                        autoComplete="new-password" 
                      />
                     {touched.password && errors.password ? (
                        <Tooltip
                          open={Boolean(errors.password)}
                          title={errors.password}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                        </Tooltip>
                      ) : (
                        <IconButton
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowPassword(!this.state.showPassowrd)
                          }
                          data-test-id="passwordVisible"
                        >
                          {this.state.showPassowrd ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      )}

                      <Typography style={webStyle.passwordHelp}>
                        {configJSON.passwordMinLengthText}
                      </Typography>
                    </Box>
                    <Box sx={{...webStyle.fieldControl, ...webStyle.fieldControlConfirmPassword}}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"confirmPassword"}
                      >
                        {configJSON.confirmPasswordText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...(touched.confirmPassword && errors.confirmPassword ? webStyle.inputElementError : {})}}
                        data-test-id="confirmPassword"
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        placeholder={configJSON.confirmPasswordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("confirmPassword", event.target.value);
                          this.setConfirmPassword(event.target.value)
                        }}
                        autoComplete="new-password" 
                      />
                      {touched.confirmPassword && errors.confirmPassword ? (
                          <Tooltip
                            open={Boolean(errors.confirmPassword)}
                            title={errors.confirmPassword}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                          </Tooltip>
                      ) : (
                        <IconButton
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowConfirmPassword(!this.state.showConfirmPassword)
                          }
                          data-test-id="confirmPasswordForm"
                        >
                          {this.state.showConfirmPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      )}
                    </Box>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="submit"
                      onClick={()=>{this.resetPasswordEmailNotification()}}
                    >
                      {configJSON.submitText}
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Box sx={webStyle.rightBlock}>
          <Box sx={webStyle.rightBlockBackground} />
          {/* Bkock right */}
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const webStyle = {
  resetPasswordContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    fontFamily: "'Inter', sans-serif",
  },
  rightBlock: {
    flex: 1,
    display: "flex",
  },
  rightBlockBackground: {
    flex: 1,
    display: "flex",
    margin: "20px",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    borderRadius: "24px",
    height: "880px",
  },
  leftBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "40px auto",
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
  },
  resetPasswordFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  fieldControlConfirmPassword: {
    marginBottom: "32px",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    padding: "10px 50px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  passwordHelp: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
  },
};
// Customizable Area End
