import React, { Component } from 'react';
import { Typography, Box, Link } from '@mui/material';
import { otherPagesMobileUiBanner } from "../../blocks/landingpage/src/assets";
export const baseURL = require("../../framework/src/config.js").baseURL;

export default class AboutUs extends Component {
  state: any = {
    getAboutusContentData: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchAboutusContentData();
  }

  fetchAboutusContentData = async () => {
    try {
      const response = await fetch(`${baseURL}/bx_block_content_management/abouts`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseJson = await response.json();
      this.setState({
        getAboutusContentData: responseJson?.data[0]?.attributes || {},
        loading: false
      });
    } catch (error) {
      this.setState({ error: error as any, loading: false });
    }
  };

  render() {
    console.log("base", baseURL);
    console.log("api--", this.state.getAboutusContentData)
    return (
      <>
        <Box component={"img"}
          className='otherPagesMobileUiBannerStyle'
          src={otherPagesMobileUiBanner}
          alt="Banner_Img"
        />
        <Box sx={webStyle.infoPageWrapper}>
          <Box component="div" sx={webStyle.infoPageHeader} dangerouslySetInnerHTML={{ __html: this.state.getAboutusContentData?.title }}>
          </Box>
          <Box component="div" sx={webStyle.infoPageDescription} dangerouslySetInnerHTML={{ __html: this.state.getAboutusContentData?.content }}>
          </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
  infoPageWrapper: {
    p: "80px 99px",
    backgroundColor: "#E4FAFF",
    "@media (max-width: 810px)": {
      p: "40px 16px"
    }
  },
  infoPageSubWrapper: {
    display: "grid",
    gap: "25px"
  },
  infoPageHeader: {
    color: "#12629A",
    fontSize: "32px",
    fontWeight: "800",
    lineHeight: "35.2px",
    fontFamily: "'Nunito Sans', sans-serif",
    mb: "20px",
    "@media (max-width: 810px)": {
      fontSize: "23px",
      fontWeight: "900",
      lineHeight: "25.3px",
    }
  },
  infoPageDescription: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "29.12px",
    fontFamily: "'Nunito Sans', sans-serif",
    'a': {
      color: '#12629A !important',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  infoPageSubHeader: {
    color: "#E5614B",
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "28.6px",
    fontFamily: "'Nunito Sans', sans-serif",
    m: "20px 0",
    "@media (max-width: 810px)": {
      fontSize: "22px",
    }
  }
}
