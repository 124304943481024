import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  showPassowrd: boolean;
  showConfirmPassword: boolean;
  validate: boolean;
  showModal: boolean;
  formData: FormData;
  // Customizable Area Start
  termsAndConditionPageContentData: any;
  getTermsAndConditionPageContentDataId: string;
  emailTextError: string;
  zipCodeError: string;
  warningMessage: string;
  zipCodeFieldFocusing: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

interface FormData {
  password_confirmation: string,
  email: string,
  full_name: string,
  password: string,
  term_agree: boolean,
  zip_code: string,
}

export default class SignUpPageController extends BlockComponent<Props, S, SS> {
  apiEmailLoginCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      showPassowrd: false,
      showConfirmPassword: false,
      validate: false,
      showModal: false,
      formData: {
        password_confirmation: "",
        email: "",
        full_name: "",
        password: "",
        term_agree: false,
        zip_code: "",
      },
      termsAndConditionPageContentData: {},
      getTermsAndConditionPageContentDataId: "",
      emailTextError: "",
      zipCodeError: '',
      warningMessage: '',
      zipCodeFieldFocusing: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.goToLogIn();
          } else {
            this.parseResponseErrors(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          if (responseJson) {
            this.setState({
              termsAndConditionPageContentData: responseJson?.data[0] || {},
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  setShowPassword = (show: boolean) => {
    this.setState({ showPassowrd: show });
  };

  setModalShow = (showModal: boolean) => {
    this.setState({ showModal });
  };

  setShowConfirmPassword = (show: boolean) => {
    this.setState({ showConfirmPassword: show });
  };

  setFormDate = (formData: FormData) => {
    this.setState({ formData });
  };

  goToLogIn = () => {
    this.props.navigation.navigate("LogInPage");
  };

  doSignUp(): boolean {
    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    const {term_agree, ...attrs} = this.state.formData;

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  // Customizable Area Start
  parseResponseErrors(response: any) {
    const errorMessage = response?.errors?.[0]?.message;

    if (errorMessage?.startsWith('Unfortunately, we are not yet able to provide service in your area.')) {
      this.setState({ zipCodeError: errorMessage });

      return;
    }

    const accountErrorMessage = response?.errors?.[0]?.account;

    if (accountErrorMessage) {
      this.setState({ warningMessage: accountErrorMessage });

      return;
    }

    this.parseApiErrorResponse(response);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    this.getTermsAndConditionPageContentData();
  }

  getTermsAndConditionPageContentData = async () => {
    // const token = await storage.get("loginToken");
    // const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      getTermsAndConditionPageContentDataId: requestMessage.messageId,
    });
    //  this.getTermsAndConditionPageContentDataId =  requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_terms_and_conditions/terms_and_conditions`
    );

    // getLoginRecentJobsMsg.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
