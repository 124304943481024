import React from 'react';
import { Typography, Box, Modal } from '@mui/material';
import AccountController, { Props } from './AccountController';
export const configJSON = require("../../blocks/landingpage/src/config");

export const reeadingIcon = require("./reeading.svg");

export default class NotificationBlock extends AccountController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Modal
        open={this.props.showNotification}
        onClose={() => {
          this.props.setNotificationVisible(false);
        }}
        sx={styles.modalBlock}
      >
        <Box sx={styles.notificationConatiner}>
          {this.state.notificationList.map((notification: any, index: number) => (
            <Box sx={index === 0 ? styles.firstNotificationMessage : styles.notificationMessage} key={index}>
              <Box sx={styles.notificationMessageTitle}>
                <Box sx={styles.notificationMessageTitleBlock}>
                  <Typography sx={styles.notificationMessageTitleText}>{notification.title}</Typography>
                  {!notification.readed && (
                    <img src={reeadingIcon} alt="reading" style={styles.notificationReadingIcon} />
                  )}
                </Box>
                <Typography sx={styles.notificationMessageDescription}>{notification.message}</Typography>
              </Box>
              <Box sx={styles.notificationMessageDate}>
                <Typography sx={styles.notificationMessageDateTitle}>{notification.date}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Modal>
    );
  }
}
const styles = {
  modalBlock: {
    "& .MuiBackdrop-root": {
      opacity: "0 !important",
    },
  }, 
  notificationConatiner: {
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '10',
    borderRadius: "12px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    width: "340px",
    height: "552px",
    overflowX: "auto",
    right: "100px",
    top: "60px",
    position: "absolute",
    padding: 0,
  },
  notificationMessage: {
    padding: "12px 16px",
    borderTop: "1px solid #CBD5E1",
    display: "flex",
    flexDirection: "column",
  },
  firstNotificationMessage: {
    padding: "12px 16px",
    display: "flex",
    flexDirection: "column",
  },
  notificationMessageTitle: {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
  },
  notificationMessageTitleBlock: {
    marginBottom: "2px",
    display: "flex",
    flexDirection: "row",
    height: "24px",
  },
  notificationMessageTitleText: {
    textAlign: "left",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#0F172A",
  },
  notificationReadingIcon: {
    height: '8px', 
    margin: "8px",
  },
  notificationMessageDescription: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
  },
  notificationMessageDate: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  notificationMessageDateTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#64748B",
  },
};
