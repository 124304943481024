Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.analiticsText = "Analytics";
exports.noDataTitleText = "No data";
exports.customerText = "Customer";
exports.yearText = "Year";
exports.saveAsPdfText = "Save as pdf";
exports.cunsumptionText = "Consumption";
exports.propertyNameText = "Property name";
exports.tenantNameText = "Tenant name";
exports.meterNumberText = "Meter #";
exports.lineChartText = "Line chart";
exports.barChartText = "Bar chart";
exports.tenantText = "Tenant";
exports.dateText = "Date";

exports.meterTypeText = "Meter type";
exports.electricTypeText = "Electric";
exports.waterTitleText = "Water";

exports.lastYearText = "Last 12 months";      
exports.previusYearText = "Previous year (2023)";    
exports.currentYearText = "2024";
// Customizable Area End
