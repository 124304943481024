import React from "react";
// Customizable Area Start
import { Typography, Box } from "@mui/material";

import { navLogo } from "./assets";
// Customizable Area End

import EmailResetPasswordPageController, {
  Props,
  configJSON,
} from "./EmailResetPasswordPageController";
// Customizable Area Start

// Customizable Area End
export default class EmailResetPasswordPage extends EmailResetPasswordPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.emailResetPasswordContainer}>
        <Box sx={webStyle.mainBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.emailResetPasswordFormContainer}>
            <Box sx={webStyle.emailResetPasswordMessageContainer}>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.heyUserText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.needToResetYourPasswordText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.ignoreIfyouDontMakeeRequestText}
              </Typography>
              <Box sx={webStyle.formContainer}>
                <button
                  style={webStyle.submitButton}
                  data-test-id="submitBtn"
                  type="submit"
                >
                  {configJSON.resetPasswordText}
                </button>
              </Box>
            </Box>
            <Typography style={webStyle.copyrightTitle}>
              {configJSON.copyrightText}
            </Typography>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  emailResetPasswordContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  mainBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "20px auto",
    maxWidth: "600px",
    width: "80%",
    borderRadius: "18px",
    border: "none",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center" as const,
    margin: "60px auto",
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
  },
  emailResetPasswordFormContainer: {
    margin: "auto",
  },
  emailResetPasswordMessageContainer: {
    paddingTop: "100px",
    paddingBottom: "100px",
    borderTop: "1px solid #CBD5E1",
    borderBottom: "1px solid #CBD5E1",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  copyrightTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    margin: "60px auto",
    textAlign: "center" as const,
  },
  submitButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
  },
};
// Customizable Area End
