import React, {useEffect, useState } from 'react';
import { Typography, 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField, 
  IconButton, 
  InputAdornment,
  Tooltip,
  InputBase,
  Radio,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  noPropertieisIcon,
  errorIcon,
  removeIcon,
  caledarIcon,
  branch1,
  branch2,
  coldIcon,
  hotIcon
} from "./assets";
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from '@mui/icons-material/Close';
import { Tenant } from './CustomersPageController';

export interface Props {
    tenantList: Tenant[];
    addMeter: (tenant: Tenant) => void;
    getTenantList: () => void;
    tenantsLoading: boolean;
    addEditTenantsDialogProps: TenantDialogProps | null;
    setAddEditTenantsDialogProps: (props: TenantDialogProps | null) => void;
    deleteTenantDialogId: number | null;
    setDeleteTenantDialogId: (id: number | null) => void;
    addEditTenant: (tenant: Tenant) => void;
    deleteTenant: (tenantId: number) => void;
    isTest?: boolean;
}

const itemsPerPage = 10;

export interface TenantDialogProps {
  isAdd?: boolean;
  initialTenant?: Tenant;
}

export const trimSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');

  export const CustomerTenants = ({tenantList, addMeter, getTenantList, tenantsLoading, addEditTenantsDialogProps, setAddEditTenantsDialogProps, deleteTenantDialogId, setDeleteTenantDialogId, addEditTenant, isTest, deleteTenant}:Props) => {
    const [validate , setValidate ] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [getTenantListRequestFired, setTenantListRequestFired] = useState(false);
    const [searchText, setSearchText] = useState('');

    const filteredTenantsList = searchText ? tenantList.filter((tenant) => tenant.tenant_name?.toLowerCase().includes(searchText.toLowerCase())) : tenantList;
    const totalPages = Math.ceil(filteredTenantsList.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedTenants = filteredTenantsList.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
      getTenantList();
      setTenantListRequestFired(true);
    }, []);

    useEffect(() => {
      if (displayedTenants.length === 0 && currentPage !== 1) {
        setCurrentPage(1);
      }
    }, [filteredTenantsList, currentPage]);

    const handleNextPage = () => {
      if (currentPage < Math.ceil(tenantList.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }

    const questionSchema = () => {
      return Yup.object().shape({
        tenant_name: Yup.string().transform((value) => trimSpaces(value)).required("Tenant Name is required"),
        meters: Yup.array()
          .of(
            Yup.object().shape({
              type: Yup.string(),
              meterNumner: Yup.string(),
              lastReading: Yup.string(),
              lastReadingDate: Yup.string(),
            })
          )
          .notRequired(),
      });
    };

    const closeAddEditDialog = () => {
      setAddEditTenantsDialogProps(null);
      setValidate(false);
    };

    if (tenantsLoading || (!getTenantListRequestFired && !isTest)) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      );
    }

    return (
      <Box sx={webStyle.tenantsContainer}>
        {tenantList.length === 0 ? (
          <Box sx={webStyle.tenantsContainer}>
            <Box sx={webStyle.noTenantsBlock}>
              <Box sx={webStyle.noTenantsBlockTitle}>
                <img src={noPropertieisIcon} style={webStyle.mainImage} />
                <Typography style={webStyle.noTenantsTitle}>No tenants</Typography>
                <Typography style={webStyle.noTenantsSubtitle}>
                  Click on the button below to start adding your tenants
                </Typography>
                <Box sx={webStyle.noTenantsAddButtonBlock}>
                  <button
                    style={webStyle.addNewButton}
                    data-test-id="addButton"
                    type="button"
                    onClick={() => setAddEditTenantsDialogProps({ isAdd: true })}
                  >
                    <Typography sx={webStyle.noTenantsButtonText}>Add tenant</Typography>
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
          <Box sx={webStyle.propertiesTableHeader}>
        <Box sx={webStyle.searchContainer}>
          <Box sx={webStyle.fieldControl}>
            <StyledInput
              data-test-id="search"
              type={"text"}
              placeholder= "Search"
              onChange={(event) => {
                setSearchText(event.target.value);
                currentPage !== 1 && setCurrentPage(1);
              }}
              sx={webStyle.searchElement}
            />
            <img src={searchIcon} alt="" style={webStyle.searchIcon} />
          </Box>
        </Box>
       
        <Box sx={webStyle.actionContainer}>
          <button
            data-test-id="addTenant"
            style={webStyle.addPropertiesButton}
            onClick={() => {
              setAddEditTenantsDialogProps({ isAdd: true });
            }}
          >
            <img src={addIcon} alt="" style={webStyle.btnIcon} />
            <Typography style={webStyle.typographyTitleText}>
              Add tenant
            </Typography>
          </button>
        </Box>
      </Box>
      <Box>
        <Typography fontFamily={"'Inter', sans-serif"} fontSize={"16px"} color={"#334155"} fontWeight={500} marginBottom={"16px"}>{filteredTenantsList.length} results</Typography>
      </Box>
      {filteredTenantsList.length > 0 && (
        <Box sx={{ minHeight: totalPages > 1 ? "674px" : "unset" }}>
          <Box sx={webStyle.propertisTable}>
            <Box sx={webStyle.propertisTableHead}>
              <Box sx={webStyle.propertisTableDataHead}>
                <Typography style={webStyle.headerTitle}>
                  Tenant name
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  # of meters
                </Typography>
              </Box>
              <Typography style={webStyle.headerTitleAction}>
                Actions
              </Typography>
            </Box>
            {displayedTenants.map((tenant: Tenant, index: any) => (
              <Box sx={webStyle.propertisTableBody} key={tenant.id}>
                <Box
                  sx={webStyle.propertisTableDataBody}
                  // onClick={() => goToMeter(tenant.id)}
                >
                  <Box sx={webStyle.propertyItem}>{tenant.tenant_name}</Box>
                  <Box sx={webStyle.propertyItem}>
                    {tenant.meters?.length || 0}
                  </Box>
                </Box>
                <Box sx={webStyle.propertyItemAction}>
                  <button
                    data-test-id={`addMeter.${index}`}
                    style={webStyle.addTenantButton}
                    onClick={() => addMeter(tenant)}
                  >
                    <img src={addIcon} alt="" style={webStyle.btnIcon} />
                    <Typography style={webStyle.typographyText}>
                      Add meter
                    </Typography>
                  </button>
                  <button
                    data-test-id={`editTenant.${index}`}
                    style={webStyle.editButton}
                    onClick={() => setAddEditTenantsDialogProps({ initialTenant: tenant })}
                  >
                    <img src={editIcon} alt="" style={webStyle.btnIcon} />
                    <Typography style={webStyle.typographyText}>
                    Edit
                    </Typography>
                  </button>
                  <button
                    data-test-id={`deleteTenant.${index}`}
                    style={webStyle.deleteButton}
                    onClick={() => setDeleteTenantDialogId(tenant.id)}
                    
                  >
                    <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                    <Typography style={webStyle.typographyText}>
                    Delete
                    </Typography>
                  </button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "5%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Previous Button */}
            <button
              onClick={() => handlePrevPage()}
              data-test-id="previous-button"
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
                marginRight: "4px",
              }}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftRoundedIcon
                sx={
                  currentPage === 1
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>

            {totalPages <= 3 ? (
              [...Array(totalPages).keys()].map((page) => (
                <button
                  key={page + 1}
                  data-test-id={"page-button"}
                  style={{
                    marginRight: "4px",
                    width:
                      currentPage === page + 1
                        ? "24px"
                        : "16px",
                    height: "24px",
                    backgroundColor:
                      currentPage === page + 1
                        ? "#DBF5FF"
                        : "inherit",
                    color:
                      currentPage === page + 1
                        ? "#1A7BA4"
                        : "#64748B",
                    border: "none",
                    textDecoration:
                      currentPage !== page + 1
                        ? "underline"
                        : "none",
                    borderRadius: "8px",
                    fontWeight:
                      currentPage !== page + 1 ? 400 : 700,
                    cursor: "pointer",
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage !== 1 && (
                  <button
                    data-test-id="ellipsis"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() => handlePageChange(1)}
                  >
                    1
                  </button>
                )}

                {currentPage > 3 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage > 2 && (
                  <button
                    data-test-id="middle-page"
                    style={{
                      marginRight: "4px",
                      width: "24px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage - 1)
                    }
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  data-test-id="middle-page2"
                  style={{
                    marginRight: "4px",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#DBF5FF",
                    color: "#1A7BA4",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() =>
                    handlePageChange(currentPage)
                  }
                >
                  {currentPage}
                </button>

                {currentPage < totalPages - 1 && (
                  <button
                    data-test-id="middle-page3"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      color: "#64748B",
                      backgroundColor: "inherit",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage + 1)
                    }
                  >
                    {currentPage + 1}
                  </button>
                )}

                {currentPage < totalPages - 2 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage !== totalPages && (
                  <button
                    data-test-id="last-page"
                    style={{
                      marginRight: "4px",
                      width:
                        currentPage === totalPages
                          ? "24px"
                          : "16px",
                      height: "24px",
                      backgroundColor:
                        currentPage === totalPages
                          ? "#DBF5FF"
                          : "inherit",
                      color:
                        currentPage === totalPages
                          ? "#1A7BA4"
                          : "#64748B",
                      border: "none",
                      textDecoration:
                        currentPage !== totalPages
                          ? "underline"
                          : "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                      fontWeight:
                        currentPage !== totalPages
                          ? 400
                          : 700,
                    }}
                    onClick={() =>
                      handlePageChange(totalPages)
                    }
                  >
                    {totalPages}
                  </button>
                )}
              </>
            )}

            <button
              data-test-id="next-page"
              onClick={() => handleNextPage()}
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
              }}
              disabled={currentPage === totalPages}
            >
              <KeyboardArrowRightRoundedIcon
                sx={
                  currentPage === totalPages
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>
          </Box>

          <Box sx={{ marginRight: "10px" }}>
            <Typography
              fontFamily={'"Nunito Sans", sans-serif'}
              fontSize={"12px"}
              color={"grey"}
            >
              {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
              {filteredTenantsList.length} results
            </Typography>
          </Box>
        </Box>
      )}
          </>
        )}
      <Dialog 
        open={!!addEditTenantsDialogProps}
        onClose={closeAddEditDialog}
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
            maxHeight: 'calc(100% - 120px)',
          },
        }}
        data-test-id="addTenantDialog"
      >
        <DialogTitle style={{ padding: '0 0 32px', position: 'relative' }} data-test-id="addTenantDialogTitle">
          <Typography variant="h6" sx={{ fontWeight: '700', fontFamily: "'Inter', sans-serif", fontSize: '24px' }}>
            {`${addEditTenantsDialogProps?.isAdd ? 'Add' : 'Edit'} tenant`}
          </Typography>
          <IconButton
            onClick={closeAddEditDialog}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
            data-test-id="addTenantDialogCloseButton"
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ marginBottom: "22px" }} />
        <DialogContent sx={{ padding: 0, overflowY: 'unset' }} data-test-id="addTenantDialogContent">
        <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Formik
              initialValues={{
                id: addEditTenantsDialogProps?.initialTenant?.id || null,
                tenant_name: addEditTenantsDialogProps?.initialTenant?.tenant_name || "",
                meters: [
                  {
                    type: "Water",
                    waterType: "Cold",
                    waterVolume: "CubicFeet",
                    waterMetric: "Standard",
                    meterNumner: "",
                    lastReading: "",
                    lastReadingDate: "",
                    meterNote: "",
                  },
                ],
              }}
              validateOnChange={true}
              validationSchema={questionSchema}
              onSubmit={(tenant: any) => {
                setValidate(false);
                addEditTenant({...tenant, tenant_name: trimSpaces(tenant.tenant_name)});
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.mainFieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"tenant_name"}>
                        Tenant name
                      </label>
                      <input
                        value={values.tenant_name}
                        style={{...webStyle.inputElemnt, ...(validate && errors.tenant_name ? webStyle.inputElementError : {})}}
                        data-test-id="tenant_name"
                        type="text"
                        onChange={(event) => {
                          setFieldValue("tenant_name", event.target.value);
                        }}
                      />
                      {validate && errors.tenant_name && (
                        <Tooltip
                          open={Boolean(errors.tenant_name)}
                          title={errors.tenant_name}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <FieldArray name="meters">
                      {(arrayHelpers: any) => (
                        <>
                          {values.meters.map((_: any, index: number) => (
                            <Box sx={webStyle.meterBlcok}>
                              <Box sx={webStyle.meterTitle}>
                                <Typography sx={webStyle.meterTitleLabel}>
                                  Meter #
                                  {index + 1}
                                </Typography>
                                <button
                                  style={webStyle.removeMeeterButton}
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    style={webStyle.btnIcon}
                                  />
                                </button>
                              </Box>
                              <Box sx={{...webStyle.arrayFieldControl, margin: 0}}>
                                <label
                                  style={webStyle.radioButtonTitleLabel}
                                  htmlFor={`meters.${index}.type`}
                                >
                                  Meter
                                </label>
                                <Box sx={webStyle.radioButtonGroup}>
                                  <Box sx={webStyle.radioButtnBlock}>
                                    <Radio
                                      sx={webStyle.radioButtonElemnt}
                                      data-test-id={`meters.${index}.type`}
                                      name={`meterType.${index}`}
                                      value={"Water"}
                                      checked={"Water" === values.meters[index].type}
                                      onChange={(event) => {
                                        setFieldValue(`meters.${index}.type`, event.target.value);
                                      }}
                                    />
                                    <label
                                      style={webStyle.radioButtonLabel}
                                      htmlFor="meter"
                                    >
                                      Water
                                    </label>
                                    
                                  </Box>
                                  <Box sx={webStyle.radioButtnBlock}>
                                    <Radio
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meters.${index}.type.electrical`}
                                        name={`meterType.${index}`}
                                        value={"Electrical"}
                                        checked={"Electrical" === values.meters[index].type}
                                        onChange={(event) => {
                                          setFieldValue(`meters.${index}.type`, event.target.value);
                                        }}
                                      />
                                      <label
                                        style={webStyle.radioButtonLabel}
                                        htmlFor="electrical"
                                      >
                                        Electrical
                                      </label>
                                  </Box>
                                </Box>
                              </Box>
                             
                                {values.meters[index].type === 'Water' && (
                                <>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <label
                                      style={webStyle.radioButtonTitleLabel}
                                      htmlFor={`meters.${index}.waterType`}
                                    >
                                      Type
                                    </label>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterType.cold`}
                                          id={`meters.${index}.waterType.cold`}
                                          name={`meters.${index}.waterType`}
                                          value="Cold"
                                          checked={values.meters[index].waterType === "Cold"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterType`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="Cold"
                                        >
                                          Cold
                                        </label>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterType.hot`}
                                          id={`meters.${index}.waterType.hot`}
                                          name={`meters.${index}.waterType`}
                                          value="Hot"
                                          checked={values.meters[index].waterType === "Hot"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterType`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="Hot"
                                        >
                                          Hot
                                        </label>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <label
                                      style={webStyle.radioButtonTitleLabel}
                                      htmlFor={`meters.${index}.waterVolume`}
                                    >
                                      Volume
                                    </label>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterVolume.cubicFeet`}
                                          id={`meters.${index}.waterVolume.cubicFeet`}
                                          name={`meters.${index}.waterVolume`}
                                          value="CubicFeet"
                                          checked={values.meters[index].waterVolume === "CubicFeet"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterVolume`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="CubicFeet"
                                        >
                                          Cu ft
                                        </label>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterVolume.gallons`}
                                          id={`meters.${index}.waterVolume.gallons`}
                                          name={`meters.${index}.waterVolume`}
                                          value="Gallons"
                                          checked={values.meters[index].waterVolume === "Gallons"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterVolume`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="Gallons"
                                        >
                                          Gallons
                                        </label>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <label
                                      style={webStyle.radioButtonTitleLabel}
                                      htmlFor={`meters.${index}.waterMetric`}
                                    >
                                      Metric
                                    </label>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterMetric.Standard`}
                                          id={`meters.${index}.waterMetric.Standard`}
                                          name={`meters.${index}.waterMetric`}
                                          value="Standard"
                                          checked={values.meters[index].waterMetric === "Standard"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterMetric`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="Standard"
                                        >
                                          Standard
                                        </label>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterMetric.10cuft`}
                                          id={`meters.${index}.waterMetric.10cuft`}
                                          name={`meters.${index}.waterMetric`}
                                          value="10cuft"
                                          checked={values.meters[index].waterMetric === "10cuft"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterMetric`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="10cuft"
                                        >
                                          10 cu.ft/gallons
                                        </label>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterMetric.100cuft`}
                                          id={`meters.${index}.waterMetric.100cuft`}
                                          name={`meters.${index}.waterMetric`}
                                          value="100cuft"
                                          checked={values.meters[index].waterMetric === "100cuft"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.waterMetric`, event.currentTarget.value);
                                          }}
                                        />
                                        <label
                                          style={webStyle.radioButtonLabel}
                                          htmlFor="100cuft"
                                        >
                                          100 cu.ft/gallons
                                        </label>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              )}

                              <Box sx={{...webStyle.arrayFieldControl, margin: '16px 0 10px'}}>
                                <label
                                  style={webStyle.inputLabel}
                                  htmlFor={`meters.${index}.meterNumner`}
                                >
                                 Meter number
                                </label>
                                <input
                                  style={webStyle.inputElemnt1}
                                  data-test-id={`meters.${index}.meterNumner`}
                                  type="text"
                                  onChange={(event) => {
                                    setFieldValue(`meters.${index}.meterNumner`, event.target.value);
                                  }}
                                />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReading`}
                                  >
                                    Last reading
                                  </label>
                                  <input
                                    style={webStyle.inputElemnt1}
                                    data-test-id={`meters.${index}.lastReading`}
                                    type="text"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.lastReading`, event.target.value);
                                    }}
                                  />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReadingDate`}
                                  >
                                    Date of last reading
                                  </label>
                                  <StyledInput
                                    data-test-id={`meters.${index}.lastReadingDate`}
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.lastReadingDate`, event.target.value);
                                    }}
                                    style={!values.meters[index].lastReadingDate ? { color: '#94A3B8' } : undefined}
                                  />
                                  <img
                                    src={caledarIcon}
                                    alt=""
                                    style={webStyle.calendarIcon as any}
                                  />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.meterNote`}
                                  >
                                    Meter note (eg.: Meter location, access code)
                                  </label>
                                  <input
                                    style={webStyle.inputElemnt1}
                                    data-test-id={`meters.${index}.meterNote`}
                                    type="text"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.meterNote`, event.target.value);
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box sx={webStyle.fieldControl1}>
                              <button
                                style={webStyle.addNewMeterButton}
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    type: "Water",
                                    waterType: 'Cold',
                                    waterVolume: "CubicFeet",
                                    waterMetric: "Standard",
                                    meterNumner: "",
                                    lastReading: "",
                                    lastReadingDate: "",
                                  });
                                }}
                              >
                                  <AddIcon style={{...webStyle.btnIcon,color:"#1A7BA4"}} />
                                <Typography style={webStyle.typographyTitleText1}>
                                Add meter
                                </Typography>
                              </button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                      <Box sx={webStyle.fieldControlGroup}>
                        <button
                          style={webStyle.cancelButton}
                          data-test-id="cancelBtn"
                          type="button"
                          onClick={closeAddEditDialog}
                        >
                         Cancel
                        </button>
                        <button
                          style={webStyle.submitButton}
                          data-test-id="submitBtn"
                          type="button"
                          onClick={() => {
                            // setTenantData({ tenant_name: values?.tenant_name });
                            // setMetersData(values?.meters);
                            setValidate(true);
                            if (Object.keys(errors).length == 0) {
                              handleSubmit();
                            }
                            // goPropertieesPage();
                          }}
                        >
                         Save
                        </button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>


        </DialogContent>
      </Dialog>



      <Dialog
              open={deleteTenantDialogId !== null}
              onClose={() => setDeleteTenantDialogId(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              data-test-id="deleteDialog"
              PaperProps={{
                style: {
                  width: "633px", 
                  padding: "40px 32px 32px 32px",
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "600", padding: '0 0 24px',fontFamily:"'Inter', sans-serif", fontSize:'24px', letterSpacing: '-1px' }}
        >
          Are you sure you want to delete this tenant?
        </DialogTitle>
        <DialogContent style={{ marginBottom: '32px', padding: 0 }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#64748B", fontFamily:"'Inter', sans-serif",fontSize:"18px"}}
          >
            This action can’t be undone, and all the data linked to this tenant will be permanently deleted and no longer accessible.
          </DialogContentText>
          <Divider style={{ marginTop: '32px' }} />
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <Button
            onClick={() => setDeleteTenantDialogId(null)}
            variant="outlined" 
            sx={webStyle.dialogCancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteTenantDialogId !== null && deleteTenant(deleteTenantDialogId)}
            sx={webStyle.confirmDelete}
            startIcon={<DeleteIcon />} 
            data-test-id="deleteDialogButton"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    );
  };

  const StyledInput = styled(InputBase)(() => ({
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

  const tooltipStyles = {
    tooltip: {
      sx: {
        color: '#FFFFFF',
        fontFamily: "'Inter', sans-serif",
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '400',
        padding: '8px 12px',
        backgroundColor: '#1E293B',
        borderRadius: '8px',
        width: 'fit-content',
        maxWidth: '480px',
        '& .MuiTooltip-arrow': {
          color: '#1E293B',
        },
      }
    }
  };

  const webStyle = {
    tenantsContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "rgba(248, 250, 252, 1)",
    },
    tenantTitleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "20px 0px",
      justifyContent: "space-between",
    },
    propertiesTableHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      margin: "20px 0px",
    },
    searchContainer: {
      width: "340px",
      display: "flex",
      flexDirection: "row",
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
    },
    tenantActionContainer: {
      display: "flex",
      flexDirection: "column",
    },
    propertisTable: {
      border: "1px solid #CBD5E1",
      borderRadius: "12px",
    },
    propertisTableHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      backgroundColor: 'rgba(248, 250, 252, 1)',
      borderRadius: '12px 12px 0 0',
    },
    propertisTableDataHead: {
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 340px)",
    },
    propertisTableBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      borderTop: "1px solid #CBD5E1",
      backgroundColor: '#FFFFFF',
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
      }
    },
    propertisTableDataBody: {
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 340px)",
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    inputElemnt: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    searchElement: {
      padding: "12px 44px 12px 12px",
      height: '44px',
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 10,
      right: 16,
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    backButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#1A7BA4",
      border: "none",
      backgroundColor: "#F8FAFC",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 0px",
    },
    addPropertiesButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "7px",
      backgroundColor: "#1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 12px",
    },
    headerTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "50%",
      overflow: "hidden",
      margin: "12px 0",
    },
    headerTitleAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "340px",
      overflow: "hidden",
      margin: "12px 0",
    },
    addTenantButton: {
      backgroundColor: "#1A7BA4",
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "none",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    editButton: {
      backgroundColor: "#FFFFFF",
      color: "#1A7BA4",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #DC2626",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    propertyItem: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "50%",
      margin: "13px 0",
      alignItems: "center",
      display: "flex",
      wordBreak: 'break-word',
    },
    propertyItemAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "340px",
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      margin: "13px 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tenantTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "29px",
      color: "#0F172A",
      wordBreak: 'break-word'
    },
    tenantSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#64748B",
      wordBreak: 'break-word',
    },
    typographyTitleText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginLeft: "6px",
      overflow: "hidden",
    },
    typographyText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    addTenantsContainer: {
      display: "flex",
      flexDirection: "row",
    },
    addTenantsBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    addTenantFormContainer: {
      maxWidth: "600px",
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "10px",
      wordBreak: 'break-word',
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
      wordBreak: 'break-word',
    },
    fieldControl1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px",
      position: "relative",
    },
    mainFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    arrayFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px",
      position: "relative",
    },
    switchTitle: {
      alignItems: "center",
      display: "flex",
    },
    radioButtonTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#64748B",
      margin: "2px 0px 12px",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
    },
    radioButtonLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      alignItems: "center",
      display: "flex",
      marginLeft: '8px',
    },
    radioButtnBlock: {
      display: "flex",
      flexDirection: "row",
      flex: '1',
      alignItems: 'center',
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
    },
    inputElemnt1: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
    },
    inputElementError: {
      border: "1px solid #F87171",
    },
    radioButtonElemnt: {
      padding: 0,
      width: '20px',
      height: '20px',
      '& .MuiSvgIcon-root': {
        color: '#64748B',
        width: '20px',
        height: '20px',
      },
      '&.Mui-checked': {
        backgroundColor: '#1A7BA4',
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
        },
        '& .MuiSvgIcon-root:first-of-type': {
          color: '#1A7BA4',
        },
        '& .MuiSvgIcon-root:nth-of-type(2)': {
          color: '#FFFFFF',
        }
      },
    },
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
    },
    numberFieldControlButton: {
      color: "#1A7BA41A",
      border: "none",
      width: "33%",
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 20px",
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
    },
    meterToReadBlcok: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    meterBlcok: {
      border: "1px solid #1A7BA44D",
      backgroundColor: "#1A7BA40F",
      padding: "16px",
      borderRadius: "12px",
      margin: "20px 0px",
    },
    meterTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    meterTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineheight: "24px",
      color: "#0F172A",
    },
    addNewMeterButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "15px",
      height: "56px",
      borderRadius: "8px",
      border: "none",
      background: "inherit",
      color: "#1A7BA4",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    removeMeeterButton: {
      height: "36px",
      width: "46px",
      border: "none",
      backgroundColor: "inherit",
    },
    typographyTitleText1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    calendarIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 13,
      backgroundColor: '#FFFFFF',
      pointerEvents: 'none',
    },
    radioButtonBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    radioButtonElement: {
      marginRight: '8px',
    },
    confirmDelete:{
       padding: "6px 12px",
       borderRadius: "5px",
       textTransform: "none",
       backgroundColor:"white",
       color:"#DC2626",
       border:"1px solid #DC2626",
       fontFamily:"'Inter', sans-serif",
       fontWeight:"600",
       height: '44px',
       marginLeft: '16px !important'
    },
   dialogCancelButton:{
       color: "#1A7BA4",
       border: "1px solid #1A7BA4",
       padding: "6px 12px",
       borderRadius: "5px",
       textTransform: "none",
       fontWeight:"700",
       fontFamily:"'Inter', sans-serif",
       fontSize: '16px',
       height: '44px',
   },
   chevronIconActive: {
     "> path": {
       fill: "#475569",
     },
   },
   chevronIconDisabled: {
     "> path": {
       fill: "#64748B",
     },
   },
   radioButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
   },
   loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },
   noTenantsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noTenantsBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noTenantsTitle: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  noTenantsSubtitle: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  mainImage: {
    width: "163px",
    height: "154px",    
  },
  addNewButton: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
  },
  noTenantsButtonText: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  noTenantsAddButtonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  };
 