const supportedZipCodes = [
    "11930",
    "11701",
    "11708",
    "11931",
    "11768",
    "11702",
    "11703",
    "11704",
    "11707",
    "11933",
    "11743",
    "11963",
    "11706",
    "11751",
    "11705",
    "11777",
    "11713",
    "11715",
    "11716",
    "11780",
    "11717",
    "11932",
    "11718",
    "11719",
    "11772",
    "11980",
    "11934",
    "11720",
    "11721",
    "11722",
    "11749",
    "11760",
    "11782",
    "11724",
    "11725",
    "11726",
    "11727",
    "11770",
    "11935",
    "11729",
    "11746",
    "11937",
    "11730",
    "11939",
    "11940",
    "11731",
    "11942",
    "11733",
    "11967",
    "11941",
    "11738",
    "6390",
    "11901",
    "11763",
    "11739",
    "11740",
    "11944",
    "11946",
    "11788",
    "11757",
    "11954",
    "11741",
    "11742",
    "00501",
    "00544",
    "11747",
    "11750",
    "11752",
    "11947",
    "11754",
    "11755",
    "11961",
    "11779",
    "11948",
    "11950",
    "11949",
    "11951",
    "11952",
    "11775",
    "11805",
    "11953",
    "11764",
    "11955",
    "11766",
    "11767",
    "11956",
    "11968",
    "11769",
    "10701",
    "10801",
    "10573",
    "10705",
    "10583",
    "10550",
    "10704",
    "10562",
    "10598",
    "10710",
    "10558",
    "10566",
    "10557",
    "10708",
    "10552",
    "10703",
    "10567",
    "10605",
    "10543",
    "10591",
    "10603",
    "10805",
    "10549",
    "10580",
    "10804",
    "10538",
    "10606",
    "10528",
    "10514",
    "10530",
    "10803",
    "10604",
    "10553",
    "10570",
    "10536",
    "10601",
    "10520",
    "10707",
    "10522",
    "10523",
    "10595",
    "10510",
    "10709",
    "10547",
    "10706",
    "10589",
    "10504",
    "10533",
    "10590",
    "10502",
    "10607",
    "10507",
    "10560",
    "10506",
    "10576",
    "10532",
    "10577",
    "10594",
    "10548",
    "10588",
    "10537",
    "10511",
    "10526",
    "10527",
    "10597",
    "10518",
    "10501",
    "10596",
    "10578",
    "10546",
    "10505",
    "10535",
    "10571",
    "10572",
    "10540",
    "10545",
    "10551",
    "10587",
    "10602",
    "10610",
    "10702",
    "10802",
    "10503",
    "10517",
    "10519",
    "10521",
    "11201",
    "11202",
    "11203",
    "11204",
    "11205",
    "11206",
    "11207",
    "11208",
    "11209",
    "11210",
    "11211",
    "11212",
    "11213",
    "11214",
    "11215",
    "11216",
    "11217",
    "11218",
    "11219",
    "11220",
    "11221",
    "11222",
    "11223",
    "11224",
    "11225",
    "11226",
    "11228",
    "11229",
    "11230",
    "11231",
    "11232",
    "11233",
    "11234",
    "11235",
    "11236",
    "11237",
    "11238",
    "11239",
    "11240",
    "11241",
    "11242",
    "11243",
    "11247",
    "11249",
    "11252",
    "11256",
    "10467",
    "10456",
    "10458",
    "10453",
    "10468",
    "10457",
    "10452",
    "10469",
    "10462",
    "10466",
    "10463",
    "10472",
    "10460",
    "10473",
    "10451",
    "10461",
    "10459",
    "10465",
    "10475",
    "10455",
    "10454",
    "10471",
    "10474",
    "10470",
    "10464",
    "10499",
    "10001",
    "10002",
    "10003",
    "10004",
    "10005",
    "10006",
    "10007",
    "10009",
    "10010",
    "10011",
    "10012",
    "10013",
    "10014",
    "10016",
    "10017",
    "10018",
    "10019",
    "10021",
    "10022",
    "10023",
    "10024",
    "10025",
    "10026",
    "10027",
    "10028",
    "10029",
    "10030",
    "10031",
    "10032",
    "10033",
    "10034",
    "10035",
    "10036",
    "10037",
    "10038",
    "10039",
    "10040",
    "10044",
    "10069",
    "10103",
    "10119",
    "10128",
    "10162",
    "10165",
    "10170",
    "10173",
    "10199",
    "10279",
    "10280",
    "10282",
    "11004",
    "11101",
    "11102",
    "11103",
    "11104",
    "11105",
    "11106",
    "11109",
    "11351",
    "11354",
    "11355",
    "11356",
    "11357",
    "11358",
    "11359",
    "11360",
    "11361",
    "11362",
    "11363",
    "11364",
    "11365",
    "11366",
    "11367",
    "11368",
    "11369",
    "11370",
    "11372",
    "11373",
    "11374",
    "11375",
    "11377",
    "11378",
    "11379",
    "11385",
    "11411",
    "11412",
    "11413",
    "11414",
    "11415",
    "11416",
    "11417",
    "11418",
    "11419",
    "11420",
    "11421",
    "11422",
    "11423",
    "11426",
    "11427",
    "11428",
    "11429",
    "11432",
    "11433",
    "11434",
    "11435",
    "11436",
    "11691",
    "11692",
    "11693",
    "11694",
    "11697",
    "10304",
    "10305",
    "10306",
    "10307",
    "10301",
    "10302",
    "10303",
    "10308",
    "10309",
    "10310",
    "10311",
    "10312",
    "10313",
    "10314"
]

export default supportedZipCodes;
