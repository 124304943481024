Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getCurrentCompanyDetailsApiEndPoint = "bx_block_custom_forms/company_details";
exports.getCurrentCompanyDetailsApiMethod = "Get";

exports.createCompanyDetailsApiEndPoint = "bx_block_custom_forms/company_details";
exports.createCompanyDetailsApiMethod = "POST";
exports.createCompanyDetailsApiContentType = "application/json";

exports.updateCompanyDetailsApiEndPoint = "bx_block_custom_forms/company_details";
exports.updateCompanyDetailsApiMethod = "Put";
exports.updateCompanyDetailsApiContentType = "application/json";

exports.getAccountSettingsApiEndPoint = "account_block/accounts/logged_user";
exports.getAccountSettingsApiMethod = "Get";
exports.getAccountSettingsApiContentType = "application/json";

exports.updateAccountSettingsApiEndPoint = "account_block/accounts/account_settings";
exports.updateAccountSettingsApiMethod = "Put";
exports.updateAccountSettingsApiContentType = "application/json";

exports.settingsText ="Settings";
exports.companyDetailsText ="Company details";
exports.accountSettingsText ="Account settings";
exports.notificationsText ="Notifications";
exports.companyNameText ="Company name";
exports.mailingAddressText ="Mailing address";
exports.cityText ="City";
exports.stateText ="State";
exports.phoneNumberText ="Phone number";
exports.faxNumberText ="Fax number";
exports.primaryEmailText ="Primary email";
exports.alternativeEmailText ="Alternative Email #";
exports.useForText ="Use for";
exports.addAlternativeEmailText ="Add alternative email";
exports.contactPersonText ="Contact person";
exports.titleOfContactPersonText ="Title of contact person";
exports.cancelButtonText ="Cancel";
exports.saveButtomText ="Save"

exports.invoiceButtonText = "Invoice";
exports.reportButtonText = "Report";
exports.reportText = "Reports";
exports.reportTitleText = "Report #";
exports.propertyTitleText = "Property";
exports.generatedAtText = "Generated at";
exports.typeText = "Type";
exports.paymentStatusText = "Payment status";
exports.actionsText = "Actions";
exports.noReportTitleText = "No reports";
exports.searchPlaceholderText = "Search";

exports.zipCodeText = "ZIP code";
exports.nameText = "Name";
exports.emailText = "Email";
exports.passwordText = "New password";
exports.confirmPasswordText = "Confirm new password";

exports.alLeastSevenLlettersText = "At least 8 characters";

// exports.confirmPasswordPlaceholderText
// Customizable Area End
