Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.invoiceButtonText = "Invoice";
exports.reportButtonText = "Report";
exports.reportText = "Reports";
exports.reportTitleText = "Report #";
exports.propertyTitleText = "Property";
exports.generatedAtText = "Generated at";
exports.typeText = "Type";
exports.paymentStatusText = "Payment status";
exports.actionsText = "Actions";
exports.noReportTitleText = "No reports";
exports.searchPlaceholderText = "Search";
// Customizable Area End
