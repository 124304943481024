import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
} from "@mui/material";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  leftArrowIcon,
} from "./assets";
// Customizable Area End

import TenantPageController, {
  Props,
  configJSON,
} from "./TenantPageController";
// Customizable Area Start
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";

// Customizable Area End
export default class TenantPage extends TenantPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.propertiesContainer}>
        <Box sx={webStyle.tenantTitleContainer}>
          <Box sx={webStyle.tenantActionContainer}>
            <button
              data-test-id="goBack"
              style={webStyle.backButton}
              onClick={this.goBack}
            >
              <img src={leftArrowIcon} alt="" style={webStyle.btnIcon} />
              <Typography style={webStyle.typographyTitleText}>
                {configJSON.backtoPropertiesText}
              </Typography>
            </button>
            <Typography sx={webStyle.tenantTitle}>
              {this.state.currentProperty.property_name}.
            </Typography>
            <Typography sx={webStyle.tenantSubTitle}>
              {this.state.currentProperty.service_address}
            </Typography>
          </Box>
        </Box>
        <Box sx={webStyle.propertiesTableHeader}>
          <Box sx={webStyle.searchContainer}>
            <Box sx={webStyle.fieldControl}>
              <input
                style={webStyle.inputElemnt}
                data-test-id="search"
                type={"text"}
                placeholder={configJSON.serachPlaceholderText}
                onChange={(event) => {
                  this.searchProperties(event.target.value);
                }}
              />
              <img src={searchIcon} alt="" style={webStyle.searchIcon} />
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <button
              data-test-id="addTenant"
              style={webStyle.addPropertiesButton}
              onClick={() => {
                this.addTenant();
              }}
            >
              <img src={addIcon} alt="" style={webStyle.btnIcon} />
              <Typography style={webStyle.typographyTitleText}>
                {configJSON.addTeenantText}
              </Typography>
            </button>
          </Box>
        </Box>
        <Box
          style={{ minHeight: this.state.totalPages > 1 ? "674px" : "unset" }}
          sx={webStyle.tableWrapper(this.state.totalPages)}
        >
          <Box sx={webStyle.propertisTable}>
            <Box sx={webStyle.propertisTableHead}>
              <Box sx={webStyle.propertisTableDataHead}>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.tenantNameText}
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.numberOfMetersText}
                </Typography>
              </Box>
              <Typography style={webStyle.headerTitleAction}>
                {configJSON.actionsText}
              </Typography>
            </Box>
            {this.state.currentTenants.map((tenant, index) => (
              <Box sx={webStyle.propertisTableBody} key={tenant.id}>
                <Box
                  sx={webStyle.propertisTableDataBody}
                  onClick={() => this.goToMeter(tenant.id)}
                >
                  <Box sx={webStyle.propertyItem}>{tenant.tenantName}</Box>
                  <Box sx={webStyle.propertyItem}>{tenant.countMeter}</Box>
                </Box>
                <Box sx={webStyle.propertyItemAction}>
                  <button
                    data-test-id={`addMeter.${index}`}
                    style={webStyle.addTenantButton}
                    onClick={() => this.addMeter(tenant.id)}
                  >
                    <img src={addIcon} alt="" style={webStyle.btnIcon} />
                    <Typography style={webStyle.typographyText}>
                      {configJSON.addMeterText}
                    </Typography>
                  </button>
                  <button
                    data-test-id={`editTenant.${index}`}
                    style={webStyle.editButton}
                    onClick={() => this.editTenant(tenant.id)}
                  >
                    <img src={editIcon} alt="" style={webStyle.btnIcon} />
                    <Typography style={webStyle.typographyText}>
                      {configJSON.editText}
                    </Typography>
                  </button>
                  <button
                    data-test-id={`deleteTenant.${index}`}
                    style={webStyle.deleteButton}
                    onClick={() => this.setDeleteButtonDialoge(tenant.id)}
                  >
                    <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                    <Typography style={webStyle.typographyText}>
                      {configJSON.deleteText}
                    </Typography>
                  </button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {this.state.totalPages > 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "5%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Previous Button */}
              <button
                onClick={() => this.handlePrevPage()}
                data-test-id="previous-button"
                style={{
                  backgroundColor: "inherit",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                  marginRight: "4px",
                }}
                disabled={this.state.currentPage === 1}
              >
                <KeyboardArrowLeftRoundedIcon
                  sx={
                    this.state.currentPage === 1
                      ? webStyle.chevronIconDisabled
                      : webStyle.chevronIconActive
                  }
                />
              </button>

              {this.state.totalPages <= 3 ? (
                [...Array(this.state.totalPages).keys()].map((page) => (
                  <button
                    key={page + 1}
                    data-test-id={"page-button"}
                    style={{
                      marginRight: "4px",
                      width:
                        this.state.currentPage === page + 1 ? "24px" : "16px",
                      height: "24px",
                      backgroundColor:
                        this.state.currentPage === page + 1
                          ? "#DBF5FF"
                          : "inherit",
                      color:
                        this.state.currentPage === page + 1
                          ? "#1A7BA4"
                          : "#64748B",
                      border: "none",
                      textDecoration:
                        this.state.currentPage !== page + 1
                          ? "underline"
                          : "none",
                      borderRadius: "8px",
                      fontWeight:
                        this.state.currentPage !== page + 1 ? 400 : 700,
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() => this.handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </button>
                ))
              ) : (
                <>
                  {this.state.currentPage !== 1 && (
                    <button
                      data-test-id="ellipsis"
                      style={{
                        marginRight: "4px",
                        width: "16px",
                        height: "24px",
                        backgroundColor: "inherit",
                        color: "#64748B",
                        border: "none",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => this.handlePageChange(1)}
                    >
                      1
                    </button>
                  )}

                  {this.state.currentPage > 3 && (
                    <span style={{ marginRight: "4px", color: "#64748B" }}>
                      ...
                    </span>
                  )}

                  {this.state.currentPage > 2 && (
                    <button
                      data-test-id="middle-page"
                      style={{
                        marginRight: "4px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "inherit",
                        color: "#64748B",
                        border: "none",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() =>
                        this.handlePageChange(this.state.currentPage - 1)
                      }
                    >
                      {this.state.currentPage - 1}
                    </button>
                  )}
                  <button
                    data-test-id="middle-page2"
                    style={{
                      marginRight: "4px",
                      width: "24px",
                      height: "24px",
                      backgroundColor: "#DBF5FF",
                      color: "#1A7BA4",
                      border: "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontWeight: 700,
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      this.handlePageChange(this.state.currentPage)
                    }
                  >
                    {this.state.currentPage}
                  </button>

                  {this.state.currentPage < this.state.totalPages - 1 && (
                    <button
                      data-test-id="middle-page3"
                      style={{
                        marginRight: "4px",
                        width: "16px",
                        height: "24px",
                        color: "#64748B",
                        backgroundColor: "inherit",
                        border: "none",
                        textDecoration: "underline",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() =>
                        this.handlePageChange(this.state.currentPage + 1)
                      }
                    >
                      {this.state.currentPage + 1}
                    </button>
                  )}

                  {this.state.currentPage < this.state.totalPages - 2 && (
                    <span style={{ marginRight: "4px", color: "#64748B" }}>
                      ...
                    </span>
                  )}

                  {this.state.currentPage !== this.state.totalPages && (
                    <button
                      data-test-id="last-page"
                      style={{
                        marginRight: "4px",
                        width:
                          this.state.currentPage === this.state.totalPages
                            ? "24px"
                            : "16px",
                        height: "24px",
                        backgroundColor:
                          this.state.currentPage === this.state.totalPages
                            ? "#DBF5FF"
                            : "inherit",
                        color:
                          this.state.currentPage === this.state.totalPages
                            ? "#1A7BA4"
                            : "#64748B",
                        border: "none",
                        textDecoration:
                          this.state.currentPage !== this.state.totalPages
                            ? "underline"
                            : "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                        fontWeight:
                          this.state.currentPage !== this.state.totalPages
                            ? 400
                            : 700,
                      }}
                      onClick={() =>
                        this.handlePageChange(this.state.totalPages)
                      }
                    >
                      {this.state.totalPages}
                    </button>
                  )}
                </>
              )}

              <button
                data-test-id="next-page"
                onClick={() => this.handleNextPage()}
                style={{
                  backgroundColor: "inherit",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
                disabled={this.state.currentPage === this.state.totalPages}
              >
                <KeyboardArrowRightRoundedIcon
                  sx={
                    this.state.currentPage === this.state.totalPages
                      ? webStyle.chevronIconDisabled
                      : webStyle.chevronIconActive
                  }
                />
              </button>
            </Box>

            <Box sx={{ marginRight: "10px" }}>
              <Typography
                fontFamily={'"Nunito Sans", sans-serif'}
                fontSize={"12px"}
                color={"grey"}
              >
                {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                {this.state.tenantList.length} results
              </Typography>
            </Box>
          </Box>
        )}
        <Dialog
          open={!!this.state.deleteButtonDialoge}
          onClose={() => this.setDeleteButtonDialoge(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "633px",
              height: "auto",
              padding: "40px 32px 32px 32px",
              gap: "10px",
              borderRadius: "16px",
              fontFamily: "'Inter', sans-serif",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title" style={webStyle.dialogTitle}>
            {configJSON.dialogTitle}
          </DialogTitle>
          <DialogContent style={{ padding: "0px 20px"}}>
            <DialogContentText
              id="alert-dialog-description"
              style={webStyle.dialogContent}
            >
              {configJSON.dialogContent}
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogActions style={webStyle.dialogAction}>
            <Button
              onClick={() => this.setDeleteButtonDialoge(null)}
              variant="outlined"
              sx={webStyle.cancelButton}
            >
              {configJSON.cancelText}
            </Button>
            <Button
              onClick={() => this.deleteTenant(this.state.deleteButtonDialoge)}
              sx={webStyle.confirmDelete}
              startIcon={<DeleteIcon />}
            >
              {configJSON.deleteText}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(248, 250, 252, 1)",
    padding: "0px 30px",
    minHeight: "100%",
  },
  tenantTitleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "space-between",
  },
  propertiesTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
  },
  searchContainer: {
    width: "340px",
    display: "flex",
    flexDirection: "row",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tenantActionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? "674px" : "unset",
    marginBottom: "30px",
  }),
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: "rgba(248, 250, 252, 1)",
    borderRadius: "12px",
  },
  propertisTableDataHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 340px)",
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  propertisTableDataBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 340px)",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  backButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 0px",
  },
  addPropertiesButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 12px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "50%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "340px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  addTenantButton: {
    backgroundColor: "#1A7BA4",
    color: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "none",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  propertyItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "50%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  propertyItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "340px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tenantTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    color: "#0F172A",
    wordBreak: "break-word",
  },
  tenantSubTitle: {
    marginTop: "10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    wordBreak: "break-word",
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginLeft: "6px",
    overflow: "hidden",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
  confirmDelete: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "8px",
    textTransform: "none",
    backgroundColor: "white",
    color: "#DC2626",
    margin: "10px 10px 0px 0px",
    border: "1px solid #DC2626",
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
    height: "40px",
    textTransform: "none",
  },
  dialogTitle: {
    fontWeight: "600",
    paddingBottom: "8px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "20px",
    whiteSpace: "nowrap",
  },
  dialogContent: {
    color: "#64748B",
    paddingBottom: "16px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "18px",
  },
  dialogAction: {
    padding: "16px",
  },
};
// Customizable Area End
