import React, { useState } from "react";
import { Typography, Box, InputBase } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { searchIcon, addIcon, noPropertieisIcon } from "./assets";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import moment from "moment";
import { Tenant } from "./CustomerCreateEditReport.web";

export interface CustomerReportsProps {
  setSelectedReport: (report: Report) => void;
  setAddReport: () => void;
  openInvoice: (report: Report) => void;

  reports?: Report[];
}

export type ProRate = {
  tenantId: string;
  tenantName: string;
  amount: number;
};

export type Report = {
  id: string;
  propertyName: string;
  generatedAt: string;
  type: "Water" | "Electricity";
  paymentStatus: "Paid" | "Pending" | "No invoice";
  tenantsList: Tenant[];
  rate: number;
  proRates?: ProRate[] | null;
  comment?: string;
};

const itemsPerPage = 10;

export const CustomerReports = ({
  setSelectedReport,
  setAddReport,
  openInvoice,
  reports,
}: CustomerReportsProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsList, setReportsList] = useState<Report[]>(reports ?? [
    {
      id: "1",
      propertyName: "Abstergo Ltd.",
      generatedAt: new Date().toString(),
      type: "Water",
      paymentStatus: "Paid",
      rate: 11.63,
      comment: "This is just a test.",
      tenantsList: [
        {
          id: "1",
          name: "Subway",
          meters: [
            {
              id: "234BH33",
              present: 1234,
              previous: 1100,
              consumptionGallons: 1000,
              consumptionCubicFeet: 2000,
              rate: "Price 1",
              amount: 300,
              type: "Hot",
              images: [
                "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
                "https://next-images.123rf.com/index/_next/image/?url=https://assets-cdn.123rf.com/index/static/assets/top-section-bg.jpeg&w=3840&q=75",
                "https://www.shutterstock.com/image-photo/calm-weather-on-sea-ocean-600nw-2212935531.jpg",
              ],
            },
            {
              id: "234BH34",
              present: 1234,
              previous: 1100,
              consumptionGallons: 1000,
              consumptionCubicFeet: 2000,
              rate: "Price 2",
              amount: 300,
              type: "Cold",
            },
            {
              id: "234BH35",
              present: 12340,
              previous: 11000,
              consumptionGallons: 1000,
              consumptionCubicFeet: 2000,
              rate: "Price 3",
              amount: 300,
              type: "Hot",
            },
          ],
        },
        {
          id: "2",
          name: "Corpo Company",
          meters: [
            {
              id: "234BH33",
              present: 1234,
              previous: 1100,
              consumptionGallons: 1000,
              consumptionCubicFeet: 2000,
              rate: "Price 1",
              amount: 300,
              type: "Cold",
            },
          ],
        },
        {
          id: "3",
          name: "Trident",
          meters: [
            {
              id: "234BH33",
              present: 1234,
              previous: 1100,
              consumptionGallons: 1000,
              consumptionCubicFeet: 2000,
              rate: "Price 2",
              amount: 300,
              type: "Cold",
            },
          ],
        },
      ],
      proRates: [
        {
          tenantId: "4",
          tenantName: "Starbucks",
          amount: 100,
        },
      ],
    },
    {
      id: "2",
      propertyName: "Abstergo Ltd.",
      generatedAt: new Date().toString(),
      type: "Electricity",
      paymentStatus: "Pending",
      rate: 8,
      tenantsList: [
        {
          id: "1",
          name: "Subway",
          meters: [
            {
              id: "234BH33",
              present: 1234,
              previous: 1100,
              consumptionKW: 1000,
              rate: "Price 2",
              amount: 300,
            },
          ],
        },
      ],
    },
    {
      id: "3",
      propertyName: "Abstergo Ltd.",
      generatedAt: new Date().toString(),
      type: "Water",
      paymentStatus: "No invoice",
      rate: 50,
      tenantsList: [
        {
          id: "1",
          name: "Subway",
          meters: [
            {
              id: "234BH33",
              present: 1234,
              previous: 1100,
              consumptionGallons: 1000,
              consumptionCubicFeet: 2000,
              rate: "Price 3",
              amount: 300,
              type: "Cold",
            },
          ],
        },
      ],
    },
  ]);
  const totalPages = Math.ceil(reportsList.length / itemsPerPage) || 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleNextPage = () => {
    if (currentPage < Math.ceil(reportsList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePaymentStatusChange = (value: any, index: number) => {
    setReportsList(
      reportsList.map((report, reportIndex) => ({
        ...report,
        paymentStatus: index === reportIndex ? value : report.paymentStatus,
      }))
    );
  };

  const handleAddReport = () => {
    setAddReport();
    setSelectedReport({
      id: "New one",
      propertyName: "Abstergo Ltd.",
      generatedAt: new Date().toString(),
      type: "Water",
      paymentStatus: "Pending",
      rate: 0,
      tenantsList: [
        {
          id: "1",
          name: "Subway",
          meters: [
            {
              id: "234BH33",
              present: 0,
              previous: 0,
              consumptionCubicFeet: 0,
              consumptionGallons: 0,
              rate: "Price 1",
              amount: 0,
              type: "Cold",
            },
            {
              id: "234BH34",
              present: 0,
              previous: 0,
              consumptionCubicFeet: 0,
              consumptionGallons: 0,
              rate: "Price 2",
              amount: 0,
              type: "Hot",
            },
            {
              id: "234BH35",
              present: 0,
              previous: 0,
              consumptionCubicFeet: 0,
              consumptionGallons: 0,
              rate: "Price 3",
              amount: 0,
              type: "Cold",
            },
          ],
        },
        {
          id: "2",
          name: "Corpo Company",
          meters: [
            {
              id: "234BH33",
              present: 0,
              previous: 0,
              consumptionCubicFeet: 0,
              consumptionGallons: 0,
              rate: "Price 1",
              amount: 0,
              type: "Cold",
            },
          ],
        },
        {
          id: "3",
          name: "Trident",
          meters: [
            {
              id: "234BH33",
              present: 0,
              previous: 0,
              consumptionCubicFeet: 0,
              consumptionGallons: 0,
              rate: "Price 2",
              amount: 0,
              type: "Cold",
            },
          ],
        },
      ],
    });
  };

  return (
    <Box sx={webStyle.reportsContainer}>
      {reportsList.length === 0 ? (
        <Box sx={webStyle.noReportsBlock}>
          <Box sx={webStyle.noReportsBlockTitle}>
            <img src={noPropertieisIcon} style={webStyle.mainImage} />
            <Typography style={webStyle.noReportsTitle}>No reports</Typography>
            <Typography style={webStyle.noReportsSubTitle}>
              Click on the button below to start adding your reports
            </Typography>
            <Box sx={webStyle.buttonBlock}>
              <button
                style={webStyle.addNewButton}
                data-test-id="submitBtn"
                type="button"
                onClick={handleAddReport}
              >
                <Typography sx={webStyle.buttonText}>Add report</Typography>
              </button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={webStyle.reportsTableHeader}>
            <Box sx={webStyle.searchContainer}>
              <Box sx={webStyle.fieldControl}>
                <StyledInput
                    type="text"
                    data-test-id="search"
                    placeholder="Search"
                />
                <img src={searchIcon} alt="" style={webStyle.searchIcon} />
              </Box>
            </Box>
            <Box sx={webStyle.actionContainer}>
              <button
                style={webStyle.addReportButton}
                onClick={handleAddReport}
                data-test-id="addReport"
              >
                <img src={addIcon} alt="" style={webStyle.btnIcon} />
                <Typography sx={webStyle.buttonText}>Add report</Typography>
              </button>
            </Box>
          </Box>
          <Box>
            <Typography
              fontFamily={"'Inter', sans-serif"}
              fontSize={"16px"}
              color={"#334155"}
              fontWeight={500}
              marginBottom={"16px"}
            >
              {reportsList.length} results
            </Typography>
          </Box>
          <Box sx={{ minHeight: totalPages > 1 ? "674px" : "unset" }}>
            <Box sx={webStyle.reportsTable}>
              <Box sx={webStyle.reportsTableHead}>
                <Box sx={webStyle.reportsDataTableHead}>
                  <Typography style={webStyle.headerTitle}>REPORT #</Typography>
                  <Typography style={webStyle.headerTitle}>PROPERTY</Typography>
                  <Typography style={webStyle.headerTitle}>
                    GENERATED AT
                  </Typography>
                  <Typography style={webStyle.headerTitle}>TYPE</Typography>
                  <Typography style={webStyle.headerTitle}>
                    PAYMENT STATUS
                  </Typography>
                </Box>
                <Typography style={webStyle.headerTitleAction}>
                  ACTIONS
                </Typography>
              </Box>
              {reportsList
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((report: Report, index: number) => (
                  <Box sx={webStyle.reportsTableBody} key={report.id}>
                    <Box sx={webStyle.reportsDataTableBody}>
                      <Box sx={webStyle.reportItem}>{report.id}</Box>
                      <Box sx={webStyle.reportItem}>{report.propertyName}</Box>
                      <Box sx={webStyle.reportItem}>
                        {moment(report.generatedAt).format("MM/DD/YYYY")}
                      </Box>
                      <Box sx={webStyle.reportItem}>{report.type}</Box>
                      <Box sx={webStyle.reportItem}>
                        <StyledSelect
                          defaultValue={report.paymentStatus}
                          sx={webStyle.select(report.paymentStatus)}
                          onChange={(event) =>
                            handlePaymentStatusChange(event.target.value, index)
                          }
                        >
                          <MenuItem value={"Paid"} sx={webStyle.selectOption}>
                            <Box
                              sx={webStyle.selectOptionText(
                                "#059669",
                                "#D1FAE5"
                              )}
                            >
                              PAID
                            </Box>
                          </MenuItem>
                          <MenuItem
                            value={"Pending"}
                            sx={webStyle.selectOption}
                          >
                            <Box
                              sx={webStyle.selectOptionText(
                                "#D97706",
                                "#FEF3C7"
                              )}
                            >
                              PENDING
                            </Box>
                          </MenuItem>
                          <MenuItem
                            value={"No invoice"}
                            sx={webStyle.selectOption}
                          >
                            <Box
                              sx={webStyle.selectOptionText(
                                "#475569",
                                "#F1F5F9"
                              )}
                            >
                              NO INVOICE
                            </Box>
                          </MenuItem>
                        </StyledSelect>
                      </Box>
                    </Box>
                    <Box sx={webStyle.reportItemAction}>
                      <button
                        style={{
                          ...(report.paymentStatus === "Paid"
                            ? webStyle.editButton
                            : webStyle.button),
                          width: "120px",
                        }}
                        onClick={() => setSelectedReport(report)}
                      >
                        <Typography style={webStyle.typographyText}>
                          {report.paymentStatus === "Paid"
                            ? "Edit report"
                            : "Open report"}
                        </Typography>
                      </button>
                      <button
                        style={{
                          ...(report.paymentStatus === "Pending"
                            ? webStyle.button
                            : webStyle.editButton),
                          ...(report.paymentStatus === "No invoice"
                            ? webStyle.disabledButton
                            : {}),
                          width: "125px",
                        }}
                        onClick={() => openInvoice(report)}
                        disabled={report.paymentStatus === "No invoice"}
                        data-test-id="openInvoice"
                      >
                        <Typography style={webStyle.typographyText}>
                          {report.paymentStatus === "Pending"
                            ? "Open invoice"
                            : "Edit invoice"}
                        </Typography>
                      </button>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
          {totalPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "5%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={handlePrevPage}
                  data-test-id="previous-button"
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    marginRight: "4px",
                  }}
                  disabled={currentPage === 1}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={
                      currentPage === 1
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>

                {totalPages <= 3 ? (
                  [...Array(totalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      data-test-id={"page-button"}
                      style={{
                        marginRight: "4px",
                        width: currentPage === page + 1 ? "24px" : "16px",
                        height: "24px",
                        backgroundColor:
                          currentPage === page + 1 ? "#DBF5FF" : "inherit",
                        color: currentPage === page + 1 ? "#1A7BA4" : "#64748B",
                        border: "none",
                        textDecoration:
                          currentPage !== page + 1 ? "underline" : "none",
                        borderRadius: "8px",
                        fontWeight: currentPage !== page + 1 ? 400 : 700,
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => handlePageChange(page + 1)}
                    >
                      {page + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {currentPage !== 1 && (
                      <button
                        data-test-id="ellipsis"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(1)}
                      >
                        1
                      </button>
                    )}

                    {currentPage > 3 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {currentPage > 2 && (
                      <button
                        data-test-id="middle-page"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        {currentPage - 1}
                      </button>
                    )}
                    <button
                      data-test-id="middle-page2"
                      style={{
                        marginRight: "4px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#DBF5FF",
                        color: "#1A7BA4",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => handlePageChange(currentPage)}
                    >
                      {currentPage}
                    </button>

                    {currentPage < totalPages - 1 && (
                      <button
                        data-test-id="middle-page3"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          color: "#64748B",
                          backgroundColor: "inherit",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        {currentPage + 1}
                      </button>
                    )}

                    {currentPage < totalPages - 2 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {currentPage !== totalPages && (
                      <button
                        data-test-id="last-page"
                        style={{
                          marginRight: "4px",
                          width: currentPage === totalPages ? "24px" : "16px",
                          height: "24px",
                          backgroundColor:
                            currentPage === totalPages ? "#DBF5FF" : "inherit",
                          color:
                            currentPage === totalPages ? "#1A7BA4" : "#64748B",
                          border: "none",
                          textDecoration:
                            currentPage !== totalPages ? "underline" : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: currentPage !== totalPages ? 400 : 700,
                        }}
                        onClick={() => handlePageChange(totalPages)}
                      >
                        {totalPages}
                      </button>
                    )}
                  </>
                )}

                <button
                  data-test-id="next-page"
                  onClick={() => handleNextPage()}
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  disabled={currentPage === totalPages}
                >
                  <KeyboardArrowRightRoundedIcon
                    sx={
                      currentPage === totalPages
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>
              </Box>

              <Box sx={{ marginRight: "10px" }}>
                <Typography
                  fontFamily={'"Nunito Sans", sans-serif'}
                  fontSize={"12px"}
                  color={"grey"}
                >
                  {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
                  {reportsList.length} results
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const StyledInput = styled(InputBase)(() => ({
    height: "44px",
    padding: "12px 44px 12px 12px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

const StyledSelect = styled(Select)(() => ({
  borderRadius: "100px",
  height: "22px",
  fontSize: "12px",
  fontWeight: 700,
  "& .MuiSelect-icon": {
    borderRadius: "0 100px 100px 0",
    borderLeft: "1px solid #FFFFFF",
    height: "22px",
    right: 0,
    top: 0,
    backgroundColor: "#F1F5F9",
  },
  "& .MuiSelect-iconOpen": {
    borderLeft: 'none',
    borderRadius: '100px 0 0 100px',
    borderRight: '1px solid #FFFFFF',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 'none',
  },
}));

const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: "#F8FAFC",
    borderRadius: "12px 12px 0 0",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElement: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchElement: {
    padding: "10px 40px 10px 10px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  addReportButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 16px",
    gap: "8px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "260px",
    margin: "12px 0px",
  },
  editButton: {
    backgroundColor: "#1A7BA4",
    color: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 8px",
    borderRadius: "7px",
    border: "none",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 8px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    justifyContent: "center",
  },
  disabledButton: {
    backgroundColor: "rgba(26, 123, 164, 0.4)",
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "260px",
    display: "flex",
    flexDirection: "row",
    margin: "13px 0px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noReportsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportsBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportsTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  noReportsSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  addNewButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  buttonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  select: (paymentStatus: string) => {
    let backgroundColor = "#D1FAE5";
    let color = "#059669";

    if (paymentStatus === "Pending") {
      backgroundColor = "#FEF3C7";
      color = "#D97706";
    }

    if (paymentStatus === "No invoice") {
      backgroundColor = "#F1F5F9";
      color = "#475569";
    }

    return {
      "& .MuiSelect-select": {
        padding: "0 24px 0 0 !important",
      },
      fontFamily: "'Inter', sans-serif",
      backgroundColor,
      color,
    };
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    padding: "8px 8px",
    margin: "0",
    borderRadius: "0",
    "&:last-of-type": {
      margin: "0",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  selectOptionText: (color: string, backgroundColor: string) => ({
    padding: "2px 8px",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "200px",
    color,
    backgroundColor,
  }),
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
  reportsDataTableHead: {
    width: "calc(100% - 260px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsDataTableBody: {
    width: "calc(100% - 260px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
};
