import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Meter {
  id: string,
  meterNumber: string,
  type: 'Water' | 'Electric',
  waterSubType?: 'Cold' | 'Hot',
  lastRiding: string,
  dateLastRiding: string,
  volume: string,
  metric: string,
}

interface S {
  token: string;
  deleteButtonDialoge: string | null,
  propertyId: number;
  currentProperty: any;
  tenantId: number;
  meterList: Meter[];
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MeterPageController extends BlockComponent<Props, S, SS> {
  apiGetMetersListCallId: string = "";
  apiDeleteeMeterCallId: string = "";
  apiShowPropertyCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      deleteButtonDialoge: null,
      propertyId: 0,
      currentProperty: {},
      tenantId: 0,
      meterList: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetMetersListCallId) {
          if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({
              meterList: responseJson?.data.map(({ attributes, id }: any) => ({
                id: id,
                meterNumber: attributes.meter_number,
                type: attributes.meter_type,
                lastRiding: attributes.last_reading,
                dateLastRiding: attributes.date_of_last_reading,
                waterSubType: attributes.water_sub_type,
                volume: attributes.volume,
                metric: attributes.metric,
              })),
            });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiDeleteeMeterCallId) {
          if (
            responseJson &&
            responseJson.message &&
            responseJson.message === "Successfully Deleted!"
          ) {
            this.getMetersList();
            this.setDeleteButtonDialoge(null);
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiShowPropertyCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            this.setState({ currentProperty: responseJson.data.attributes });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData('role');

    if (userRole !== 'user') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }

    await this.getToken();
    if (this.state.propertyId !== 0) {
      await this.getPropertyById(this.state.propertyId);
    }
    await this.getMetersList();
    // await Promise.all([this.updatePagination()]);
  }

  getMetersList = () => {
    const header = {
      "Content-Type": configJSON.getMetersApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMetersListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMetersApiEndPoint +
        `/${this.state.propertyId}/tenants/${this.state.tenantId}/meters`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMetersApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPropertyById = (propertyId: number) => {
    const header = {
      "Content-Type": configJSON.showPropertyApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowPropertyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showPropertyApiEndPoint + "/" + propertyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showPropertyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchProperties = (search: string) => {
    console.log({ search });
  };

  addMeter = () => {
    setStorageData('propertyId', this.state.propertyId);
    setStorageData('tenantId', this.state.tenantId);
    setStorageData("isEditMeter", "false");
    setStorageData("isEditTenant", "false");

    this.props.navigation.navigate("AddTenantPage");
  };

  editMeter = (id: string) => {
    setStorageData('propertyId', this.state.propertyId);
    setStorageData('tenantId', this.state.tenantId);
    setStorageData('meterId', id);
    setStorageData("isEditMeter", "true")

    this.props.navigation.navigate("AddTenantPage");
  };

  deleteMeter = (id: string | null) => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteeMeterCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMeterApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteMeterApiEndPoint +
        `/${this.state.propertyId}/tenants/${this.state.tenantId}/meters/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goToLogInPage = () => {
    this.props.navigation.navigate("LogInPage");
  };

  setDeleteButtonDialoge = (deleteButtonDialoge: string | null) => {
    this.setState({ deleteButtonDialoge });
  };

  goBack = () => {
    setStorageData('propertyId', this.state.propertyId);
    setStorageData('tenantId', this.state.tenantId);

    this.props.navigation.navigate("TenantPage");
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getToken = async () => {
    let token = await getStorageData("token");
    let propertyId = await getStorageData('propertyId');
    let tenantId = await getStorageData('tenantId');

    this.setState({ token: token, propertyId: propertyId, tenantId: tenantId });
  };
  // Customizable Area End
}
