import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  ButtonBase,
  Tooltip,
} from "@mui/material"
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

type CustomerGalleryProps = {
    urls: string[];
};

export const CustomerGallery = ({ urls }: CustomerGalleryProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);
  const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false);

  const showNextImage = (e: any) => {
    e.stopPropagation();
    setCurrentImageIndex(currentImageIndex + 1);
  };

  const showPreviousImage = (e: any) => {
    e.stopPropagation();
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const handleCloseDialog = () => setIsOpen(false);

  const handleOpenDialog = () => setIsOpen(true);

  const copyImageToClipboard = async (url: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // Fetch the image as a Blob
        const response = await fetch(url);
        const blob = await response.blob();const img = new Image();
        img.src = URL.createObjectURL(blob);

        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });

        // Create a canvas to draw the image
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('Could not create canvas context');
        }

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0);

        // Convert canvas to blob
        canvas.toBlob(async (canvasBlob) => {
          if (!canvasBlob) {
            throw new Error('Could not convert canvas to blob');
          }

          // Copy to clipboard
          // @ts-ignore
          await navigator.clipboard.write([
            // @ts-ignore
            new ClipboardItem({ 'image/png': canvasBlob })
          ]);

          // Show success tooltip for 3 seconds
          setCopiedTooltipOpen(true);
          setTimeout(() => setCopiedTooltipOpen(false), 3000);
        }, 'image/png');
      }
    } catch (_) {};
}
const downloadImages = async (urls: string[]) => {
    setDownloadTooltipOpen(true);
    for (let index = 0; index < urls.length; index++) {
        const url = urls[index];
        try {
            const response = await fetch(url);
            if (!response.ok) {
                continue;
            }
            const blob = await response.blob();

            const blobUrl = URL.createObjectURL(blob);

            const fileName = `report-image-${index + 1}.jpg`;

            const anchor = document.createElement('a');
            anchor.href = blobUrl;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            URL.revokeObjectURL(blobUrl);
        } catch (_) {}
    }
    setTimeout(() => setDownloadTooltipOpen(false), 3000);
}

  return (
    <Box>
        <IconButton 
          sx={{...webStyle.button, ...(!urls.length ? webStyle.disabledButton : {})}}
          onClick={handleOpenDialog}
          disabled={!urls.length}
          data-test-id="openDialogButton"
        >
          <CameraAltOutlinedIcon sx={{...webStyle.icon, ...(!urls.length ? webStyle.disabledIcon : {})}} />
          {urls.length > 0 && (
            <Box sx={webStyle.badge} data-test-id="badgeCount">
                {urls.length}
            </Box>
          )}
        </IconButton>
        <Dialog open={isOpen} onClose={handleCloseDialog} PaperProps={{ sx: webStyle.dialogPaper }}>
            <DialogContent sx={webStyle.dialogContent}>
                <Box sx={webStyle.imageContainer}>
                    <img
                        src={urls[currentImageIndex]}
                        alt={`Image ${currentImageIndex + 1}`}
                        style={webStyle.image}
                    />
                </Box>
            </DialogContent>
            {currentImageIndex !== 0 && (
                <IconButton
                    sx={{...webStyle.navigationButton, ...webStyle.navigationButtonLeft}}
                    onClick={showPreviousImage}
                >
                    <ChevronLeftRoundedIcon sx={webStyle.navigationIcon} />
                </IconButton>
            )}
            {currentImageIndex !== urls.length - 1 && (
                <IconButton
                    sx={{...webStyle.navigationButton, ...webStyle.navigationButtonRight}}
                    onClick={showNextImage}
                >
                    <ChevronRightRoundedIcon sx={webStyle.navigationIcon} />
                </IconButton>
            )}
            <Box sx={webStyle.counter}>
                <Box sx={webStyle.counterText}>{currentImageIndex + 1}/{urls.length}</Box>
            </Box>
            <Box sx={webStyle.dialogButtonsWrapper}>
                <Tooltip
                    open={copiedTooltipOpen}
                    title="Image copied to clipboard!"
                    componentsProps={tooltipStyles}
                    placement="top"
                    arrow
                >
                    <ButtonBase sx={webStyle.dialogButton} onClick={() => copyImageToClipboard(urls[currentImageIndex])}>
                        Copy
                    </ButtonBase>
                </Tooltip>
                <Tooltip
                    open={downloadTooltipOpen}
                    title="Download started! Your images will be ready shortly."
                    componentsProps={tooltipStyles}
                    placement="top"
                    arrow
                >
                    <ButtonBase sx={webStyle.dialogButton} onClick={() => downloadImages(urls)}>
                        Download
                    </ButtonBase>
                </Tooltip>
            </Box>
        </Dialog>
    </Box>
  );
};

const tooltipStyles = {
    tooltip: {
      sx: {
        color: "#FFFFFF",
        fontFamily: "'Inter', sans-serif",
        fontSize: "14px",
        fontWeight: "400",
        padding: "8px 12px",
        backgroundColor: "#334155",
        borderRadius: "8px",
        width: "fit-content",
        maxWidth: "480px",
        "& .MuiTooltip-arrow": {
          color: "#334155",
        },
      },
    },
  };

const webStyle = {
    dialogPaper: {
        overflowY: 'initial',
        borderRadius: '18px',
    },
    button: {
      position: 'relative',
      height: '28px',
      width: '28px',
      backgroundColor: '#E8F9FC',
      borderRadius: '50%',
    },
    disabledButton: {
        backgroundColor: '#F1F5F9',
    },
    icon: {
      height: '20px',
      width: '20px',
      color: '#1A7BA4',
    },
    disabledIcon: {
        color: '#CBD5E1',
    },
    badge: {
      position: 'absolute',
      top: '-4px',
      right: '-4px',
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#475569',
        color: '#FFFFFF',
        fontFamily: '"Inter", sans-serif',
        fontSize: '10px',
        fontWeight: 500,
    },
    dialogContent: {
      maxWidth: '800px',
      padding: '0',
      position: 'relative',
      borderRadius: '18px',
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      height: '100%'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain' as any,
    },
    navigationContainer: {
      position: 'absolute',
      inset: '0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    navigationButton: {
      height: '48px',
      width: '48px',
      borderRadius: '50%',
      position: 'absolute',
      top: "50%",
      transform: 'translateY(-50%)',
      backgroundColor: '#FFFFFF',
      color: '#475569',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
    navigationIcon: {
        width: '48px',
        height: '48px',
    },
    navigationButtonLeft: {
        left: '-84px',
    },
    navigationButtonRight: {
        right: '-84px',
    },
    counter: {
      position: 'absolute',
      bottom: '-52px',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    counterText: {
        fontSize: '20px',
        color: '#FFFFFF',
        fontWeight: 700,
        fontFamily: '"Inter", sans-serif',
        backgroundColor: '#1E293B',
        padding: '4px 20px',
        borderRadius: '12px',
    },
    dialogButtonsWrapper: {
        position: 'absolute',
        top: '-60px',
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
    },
    dialogButton: {
        height: '44px',
        padding: '11px 16px',
        fontFamily: '"Inter", sans-serif',
        fontWeight: 600,
        fontSize: '14px',
        color: '#334155',
        backgroundColor: '#FFFFFF',
        borderRadius: '7px',
    },
  };