Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.postforgotPasswordEndPoint = "bx_block_forgot_password/passwords/forgot";

exports.logoText = "THE METER READING SERVICES";
exports.resendEmailText = "Resend email";
exports.donthaveAnEmailText = "Didn’t get an email?";
exports.getLinkText = "Get a link";
exports.forgotPasswordText = "Forgot password?";
exports.emailText = "Email";
exports.emailPlaceholderText = "email@example.com";
exports.willSeendYouEmailText = "We’ll send you an email to reset your password";

// Customizable Area End