export const closeIcon = require("../assets/closeIcon.svg");

export const addIcon = require("../assets/add.svg");
export const removeIcon = require("../assets/removeIcon.svg");

export const companyNameIcon = require("../assets/companyName.svg");
export const managerIcon = require("../assets/manager.svg");
export const numberIcon = require("../assets/number.svg");
export const contactInfoIcon = require("../assets/contactInfo.svg");

export const leftArrowIcon = require("../assets/leftArrow.svg");
export const rightArrowIcon = require("../assets/rightArrow.svg");
export const successIcon = require("../assets/success.svg");
export const uploadIcon = require("../assets/upload.svg");
export const errorIcon = require("../assets/errorIcon.svg");
