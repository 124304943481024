import React, { Component } from 'react';
import { Link, Box } from '@mui/material';

interface ReadMoreProps {
    content: string;
    maxChars: number;
}

interface ReadMoreState {
    isExpanded: boolean;
}

/** This component displays a truncated version of the provided *content*, limiting it to *maxChars* characters.
 * If the text exceeds *maxChars* characters, it appends a "Read more" link inline with the text.
 * Clicking the "Read more" link reveals the full text, and the link changes to "Show less".
 * Clicking "Show less" will collapse the text back to the truncated version.
*/
export default class ReadMore extends Component<ReadMoreProps, ReadMoreState> {
    constructor(props: ReadMoreProps) {
        super(props);
        this.state = {
          isExpanded: false,
        };
      }

  toggleReadMore = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  render() {
    const { isExpanded } = this.state;
    const { content, maxChars } = this.props;

    // Strip all HTML tags except <a>
    const textWithLinks = content.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '');

    return (
      <Box component="span">
        {isExpanded || textWithLinks.length <= maxChars ? (
          // Render full content if expanded or less than maxChars
          <span dangerouslySetInnerHTML={{ __html: content }} data-test-id="expanded-content" />
        ) : (
          // Render truncated content with HTML safely applied
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: `${textWithLinks.slice(0, maxChars)}...`,
              }}
              data-test-id="partial-content"
            />
            <Link
              component="button"
              variant="body1"
              onClick={this.toggleReadMore}
              sx={{...readMoreStyles.showButton, ...readMoreStyles.showMoreButton}}
              data-test-id="read-more-button"
            >
              Read more
            </Link>
          </>
        )}

        {isExpanded && (
          <Link
            component="button"
            variant="body1"
            onClick={this.toggleReadMore}
            sx={readMoreStyles.showButton}
          >
            Show less
          </Link>
        )}
      </Box>
    );
  }
}

const readMoreStyles = {
    showButton: {
        color: '#12629A',
        fontSize: '18px',
        fontFamily: "'Nunito Sans', sans-serif",
        textDecorationColor: '#12629A',
        '&:hover': {
          textDecoration: 'none'
        }
    },
    showMoreButton: {
        margin: '-3px 0 0 5px',
    }
}